import { useEffect } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonScale, ButtonKind } from 'bloko/blocks/button';
import { FormSpacer } from 'bloko/blocks/form';
import NotificationFooter from 'bloko/blocks/notificationManager/NotificationFooter';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { MarkAsViewedEvent } from 'Modules/Notices';
import { useDeleteUserNotificationById } from 'src/components/SupernovaMainMenu/UserNotifications/useDeleteNotification';
import SynchronizedNotification from 'src/components/UserNotifications/common/SynchronizedNotification';
import { ComplexNotificationComponentProps } from 'src/components/UserNotifications/common/notificationComponentProps';
import translation from 'src/components/translation';

const TrlKeys = {
    text: 'employerItAccreditation.notification.text',
    add: 'employerItAccreditation.notification.add',
    close: 'employerItAccreditation.notification.close',
};

const EmployerItAccreditation: TranslatedComponent<ComplexNotificationComponentProps> = ({
    trls,
    onClose,
    ...notification
}) => {
    const [, deleteNotification] = useDeleteUserNotificationById(notification.id);

    useEffect(() => {
        void deleteNotification();
    }, [deleteNotification]);

    return (
        <SynchronizedNotification
            notification={notification}
            markAsViewedEvent={MarkAsViewedEvent.None}
            onClose={onClose}
            analyticsElementName="employer_it_accreditation_notice"
        >
            <p>{trls[TrlKeys.text]}</p>
            <NotificationFooter>
                <Button
                    Element={SPALink}
                    to={'/employer/edit/sidebar'}
                    scale={ButtonScale.Small}
                    kind={ButtonKind.Primary}
                    onClick={() => {
                        Analytics.sendHHEventButtonClick('employer_it_accreditation_notice', {
                            goal: 'PORTFOLIO-18871',
                        });
                        onClose();
                    }}
                >
                    {trls[TrlKeys.add]}
                </Button>
                <FormSpacer />
                <Button scale={ButtonScale.Small} onClick={onClose}>
                    {trls[TrlKeys.close]}
                </Button>
            </NotificationFooter>
        </SynchronizedNotification>
    );
};

export default translation(EmployerItAccreditation);
