import { H3 } from 'bloko/blocks/header';
import Vspacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Counter from 'src/components/LoyaltySurveyModal/Counter';
import {
    FORM_STEPS_ARRAY,
    FormSteps,
    SKIP_ANSWER_VALUE,
    SurveyResultType,
} from 'src/components/LoyaltySurveyModal/constants';
import translation from 'src/components/translation';

import styles from './step-header.less';

interface StepHeaderProps {
    step: number;
    total: number;
    title: string;
    surveyResult: SurveyResultType;
}

const TrlKeys = {
    lastQuestion: 'surveys.loyalty.popup.lastQuestion',
    counterPart: 'surveys.loyalty.popup.counterPart',
    stepText: {
        rate: 'surveys.loyalty.popup.steps.4.rate',
    },
};

const StepHeader: TranslatedComponent<StepHeaderProps> = ({ trls, step, total, title, surveyResult }) => {
    const counter = step < total - 1 ? `${step + 1} ${trls[TrlKeys.counterPart]} ${total}` : trls[TrlKeys.lastQuestion];

    let titleText = <>{title}</>;
    if (FORM_STEPS_ARRAY[step] === FormSteps.ReasonsText) {
        const answerCode = surveyResult[FormSteps.RecommendRadioWithNum]?.answerCode;
        const answerSkipped = answerCode === SKIP_ANSWER_VALUE;
        titleText = (
            <>
                {!answerSkipped && answerCode && `${trls[TrlKeys.stepText.rate]}${answerCode}.`}
                <br />
                {title}
            </>
        );
    }

    return (
        <div className={styles.loyaltySurveyModalSubheader}>
            <Counter>{counter}</Counter>
            <H3>
                <span className={styles.stepHeaderTitle}>{titleText}</span>
            </H3>
            <Vspacing base={4} />
        </div>
    );
};
export default translation(StepHeader);
