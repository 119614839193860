import { Button } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import HelpModalActionWithButton from 'src/components/SupernovaMainMenu/HelpModal/HelpModalActionWithButton';
import { useTrackClicks } from 'src/components/SupernovaMainMenu/useTrackClicks';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NavItem } from 'src/models/supernovaNaviMenu';

const TrlKeys = {
    personalManager: 'supernova.modal.help.personal.manager',
    personalManagerContact: 'supernova.modal.help.personal.manager.contact',
};

const HelpModalPersonalManager: TranslatedComponent<{ item: NavItem }> = ({ trls, item }) => {
    const personalManager = useSelector(({ personalManager }) => personalManager);
    const { name, trackClicks, analytics, url, external, translations } = item;

    const trackClick = useTrackClicks(name, trackClicks, analytics);

    return (
        <HelpModalActionWithButton
            item={item}
            avatarProps={{
                size: 48,
                mode: 'image',
                image: personalManager.smallPhoto,
                fallbackMode: 'placeholder',
                'aria-label': 'manager photo',
            }}
            cellTextTitle={`${personalManager.firstName} ${personalManager.lastName}`}
            cellTextSubtitle={translations.name}
            actionButton={
                <Button
                    Element={SPALink}
                    target={external ? '_blank' : undefined}
                    to={url}
                    onClick={trackClick}
                    mode="secondary"
                    data-qa={`mainmenu_${name}`}
                >
                    {trls[TrlKeys.personalManagerContact]}
                </Button>
            }
        />
    );
};

export default translation(HelpModalPersonalManager);
