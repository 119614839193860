import Debug from 'HHC/Debug';

import { useSelector } from 'src/hooks/useSelector';

/**
 * Хук для определения включена ли динамическая настройка.
 *
 * Рекомендуется использовать когда значение из стора явно задано как true или false.
 *
 * Если настройка задана как число или строка приведение к true/false произойдет по правилам javascript.
 *
 * Убедитесь, что полученное значение ожидаемо для вас.
 *
 * @param {string} featureName название динамической настройки;
 * @example
 * const featureEnabled = useFeatureEnabled('featureName')
 * @returns {Boolean} true если настройка включена, false если настройка выключена или не найдена
 */
const useFeatureEnabled = (featureName: string): boolean => {
    const featureValue = useSelector((state) => state.features[featureName]);
    if (typeof featureValue === 'boolean') {
        return featureValue;
    }
    if (featureValue !== undefined) {
        Debug.log(
            'warn',
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-base-to-string
            `Значение динамической настройки ${featureName} == ${featureValue} (type ${typeof featureValue}), приведение к true/false произойдет по правилам javascript, убедитесь что полученное значение ожидаемо для вас`
        );
    }
    return Boolean(featureValue);
};

export { useFeatureEnabled };
