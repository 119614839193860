import { useCallback, useLayoutEffect, useRef } from 'react';

import { useDebounce } from '@hh.ru/magritte-ui';

/**
 * Хук, помогающий обрабатывать актуальное значение document.title.
 * При вызове возвращаемого значения снаружи мы сначала дождемся, пока зарезолвится title (он может быть определен сразу или добавлен в head позже с помощью react-head),
 * и только потом вызываем переданный callback, вызывая дальше его каждый раз при обновлении title внутри head
 */
const useWaitForDocumentTitle = (): ((cb: () => void) => void) => {
    const isDocumentTitleResolved = useRef(false);
    const previousCallbackRef = useRef<() => void>();

    const debouncedCallback = useDebounce(() => previousCallbackRef.current?.());

    /*
     * Возвращаемый метод принимает callback, вызов которого произойдет при резолве title, а также при последующих его изменениях.
     * Мы передаем внутрь колбэк, которому необходимо актуальное значение document.title
     */
    const waitForDocumentTitle = useCallback(
        (cb: () => void) => {
            previousCallbackRef.current = cb;
            isDocumentTitleResolved.current && debouncedCallback();
        },
        [debouncedCallback]
    );

    useLayoutEffect(() => {
        isDocumentTitleResolved.current = !!document.title;

        const observer = new MutationObserver((mutations) => {
            const isTitleAdded = mutations.some(({ addedNodes }) =>
                [...addedNodes].some(({ nodeName }) => nodeName === 'TITLE')
            );

            if (isTitleAdded) {
                isDocumentTitleResolved.current = true;
                previousCallbackRef.current && waitForDocumentTitle(previousCallbackRef.current);
            }
        });

        observer.observe(document.head, { childList: true });

        return () => {
            observer.disconnect();
        };
    }, [waitForDocumentTitle]);

    return waitForDocumentTitle;
};

export { useWaitForDocumentTitle };
