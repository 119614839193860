import {
    FormSteps,
    RECOMMENDATION_PROBABILITY_MIN_IS_LESS_THEN_VALUE,
    SKIP_ANSWER_VALUE,
    SurveyResultType,
} from 'src/components/LoyaltySurveyModal/constants';

const DISABLE_VALUE = -1;

export const findPosition = (step: FormSteps, arr: FormSteps[]): number => arr?.findIndex((item) => item === step);

// начинаем опрос с последнего неотвеченного при повторном заходе
export const calculatePassedSteps = (survey: SurveyResultType, positions: Record<FormSteps, number>) => {
    let passedStepsCount = Object.keys(survey).length;
    const reasons = positions[FormSteps.ReasonsText];
    const improvements = positions[FormSteps.ImprovementsCheckboxes];
    const rating = positions[FormSteps.RatingRadioWithText];
    const preference = positions[FormSteps.PreferenceRadioWithText];
    const protectedPD = positions[FormSteps.ProtectedPDRadioWithNum];
    const nonTransferablePD = positions[FormSteps.NonTransferablePDRadioWithNum];
    const recommend = survey[FormSteps.RecommendRadioWithNum]?.answerCode;
    const isRecommendSkip = recommend === SKIP_ANSWER_VALUE;

    if (
        (survey[FormSteps.ProtectedPDRadioWithNum]?.answerCode && nonTransferablePD !== DISABLE_VALUE) ||
        survey[FormSteps.NonTransferablePDRadioWithNum]?.answerCode
    ) {
        passedStepsCount = nonTransferablePD;
    } else if (
        (survey[FormSteps.PreferenceRadioWithText]?.answerCode && protectedPD !== DISABLE_VALUE) ||
        (survey[FormSteps.ProtectedPDRadioWithNum]?.answerCode && nonTransferablePD === DISABLE_VALUE)
    ) {
        passedStepsCount = protectedPD;
    } else if (
        survey[FormSteps.RatingRadioWithText]?.answerCode ||
        (survey[FormSteps.PreferenceRadioWithText]?.answerCode &&
            protectedPD === DISABLE_VALUE &&
            nonTransferablePD === DISABLE_VALUE)
    ) {
        passedStepsCount = preference;
    } else if (survey[FormSteps.ImprovementsCheckboxes]?.answers.length || isRecommendSkip) {
        passedStepsCount = rating;
    } else if (
        survey[FormSteps.ReasonsText]?.answerText === '' &&
        survey[FormSteps.ReasonsText]?.answerCode !== SKIP_ANSWER_VALUE
    ) {
        passedStepsCount = reasons;
    } else if (survey[FormSteps.ReasonsText]?.answerCode === SKIP_ANSWER_VALUE) {
        passedStepsCount =
            Number(recommend) < RECOMMENDATION_PROBABILITY_MIN_IS_LESS_THEN_VALUE && improvements !== DISABLE_VALUE
                ? improvements
                : rating;
    }

    return passedStepsCount;
};

// генерим массив чисел 1-{введенное число} и переводим их в строку, перемешиваем массив
export const getRandomStringArray = (count: number) => {
    const array = [...Array(count).keys()].map((i) => (i + 1).toString());
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};
