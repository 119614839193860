import { useCallback } from 'react';

import Analytics from '@hh.ru/analytics-js';

import { SupernovaSearchItem } from 'src/models/supernovaSearchName';

export interface SendSuggestAnalyticsProps {
    sendSuggestShownAnalytics: () => void;
    sendSuggestItemClickAnalytics: (item: SupernovaSearchItem) => void;
}

const useSendAnalytics = (): SendSuggestAnalyticsProps => {
    const sendSuggestShownAnalytics = useCallback(() => {
        Analytics.sendHHEvent('element_shown', { elementName: 'search_suggest' });
    }, []);

    const sendSuggestItemClickAnalytics = useCallback((item: SupernovaSearchItem) => {
        Analytics.sendHHEvent('search_suggest_item_click', { selectedText: item.text });
    }, []);

    return { sendSuggestShownAnalytics, sendSuggestItemClickAnalytics };
};

export { useSendAnalytics };
