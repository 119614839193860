import { useCallback } from 'react';

import Debug from 'HHC/Debug';
import { useSelector } from 'src/hooks/useSelector';
import { fetcher } from 'src/utils/fetcher';

declare global {
    interface FetcherPutApi {
        '/shards/user_statuses/job_search_status_notification_read': {
            body: void;
            queryParams: { triggerName: string };
            response: void;
        };
    }
}

const useMarkAsRead = (triggerNameParam?: string | null): (() => void) => {
    const triggerNameState = useSelector((state) => state.applicantUserStatusesTrigger);
    const triggerName = triggerNameParam || triggerNameState;

    const markRead = useCallback(() => {
        if (!triggerName) {
            Debug.log('error out', 'No user status triggerName provided');
            return;
        }
        void fetcher
            .put('/shards/user_statuses/job_search_status_notification_read', undefined, {
                params: {
                    triggerName,
                },
            })
            .catch(console.error);
    }, [triggerName]);

    return markRead;
};

export { useMarkAsRead };
