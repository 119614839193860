import { useRef } from 'react';

import { Button, TooltipHover } from '@hh.ru/magritte-ui';
import { AdjustmentsOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { SupernovaSearchName } from 'src/models/supernovaSearchName';

import { useAdvancedSearchHref } from 'src/components/SupernovaSearch/useAdvancedSearchHref';

const TrlKeys = {
    labelText: 'supernova.search.advanced.tooltip',
};

interface Props {
    searchName: SupernovaSearchName;
    shortView?: boolean;
}

const AdvancedSearchButton: TranslatedComponent<Props> = ({ trls, searchName, shortView }) => {
    const buttonRef = useRef(null);
    const href = useAdvancedSearchHref(searchName);

    if (searchName === SupernovaSearchName.Employers) {
        return null;
    }

    return (
        <>
            {shortView && (
                <TooltipHover placement="bottom-right" activatorRef={buttonRef}>
                    {trls[TrlKeys.labelText]}
                </TooltipHover>
            )}
            <Button
                Element={SPALink}
                to={href}
                mode="secondary"
                style="neutral"
                data-qa="advanced-search"
                icon={<AdjustmentsOutlinedSize24 />}
                aria-label={trls[TrlKeys.labelText]}
                hideLabel={shortView}
                ref={buttonRef}
            >
                {trls[TrlKeys.labelText]}
            </Button>
        </>
    );
};

export default translation(AdvancedSearchButton);
