import { useNote } from 'src/hooks/useNote';
import { useSelector } from 'src/hooks/useSelector';
import { EmployerState } from 'src/models/employerStatus';

export enum EmployerVerificationState {
    Approved = 'approved',
    NewWithoutDocs = 'newWithoutDocs',
    NewWithDocs = 'newWithDocs',
    NotVerifiedWithoutDocs = 'notVerifiedWithoutDocs',
    NotVerifiedWithDocs = 'notVerifiedWithDocs',
}

export enum BannerType {
    Candidates = 'candidates',
    Vacancies = 'vacancies',
    VacancyPreview = 'vacancyPreview',
    Profile = 'profile',
}

export enum TooltipType {
    Vacancy = 'vacancy',
    Profile = 'profile',
}

export type Type = BannerType & TooltipType;

const Notes = {
    newWithDocsBannerHasClosed: 'new_with_docs_banner_has_closed',
    newWithDocsBannerShownCount: 'new_with_docs_banner_shown_count',
    notVerifiedWithoutDocsBannerHasClosed: 'not_verified_without_docs_banner_has_closed',
    notVerifiedWithoutDocsBannerShownCount: 'not_verified_without_docs_banner_shown_count',
};

export const useEmployerVerificationState = (
    candShowIcon = false,
    canShowBanner = true
): {
    employerVerificationState: EmployerVerificationState | undefined;
    shouldShowIcon: boolean;
    shouldShowBanner: boolean;
    isUnverifiedHhRuEmployer: boolean;
    setBannerHasClosed?: () => void;
    setBannerShownCount?: () => void;
    employerId: string;
    employerState: EmployerState | null;
} => {
    const { state, hasDocuments } = useSelector(({ employerStatus }) => employerStatus);
    const employerId = useSelector((state) => state.employerId);
    const isSudo = useSelector(({ actualAccount }) => actualAccount !== null);

    const [newWithDocsBannerHasClosed, setNewWithDocsBannerHasClosed] = useNote<boolean>(
        Notes.newWithDocsBannerHasClosed
    );
    const [newWithDocsBannerShownCount, setNewWithDocsBannerShownCount] = useNote<number>(
        Notes.newWithDocsBannerShownCount
    );
    const [notVerifiedWithoutDocsBannerHasClosed, setNotVerifiedWithoutDocsBannerHasClosed] = useNote<boolean>(
        Notes.notVerifiedWithoutDocsBannerHasClosed
    );
    const [notVerifiedWithoutDocsBannerShownCount, setNotVerifiedWithoutDocsBannerShownCount] = useNote<number>(
        Notes.notVerifiedWithoutDocsBannerShownCount
    );

    let employerVerificationState;
    let shouldShowBanner = false;
    let setBannerHasClosed;
    let setBannerShownCount;

    if (state === EmployerState.Approved) {
        employerVerificationState = EmployerVerificationState.Approved;
        shouldShowBanner = false;
    }

    if (state === EmployerState.New && !hasDocuments) {
        employerVerificationState = EmployerVerificationState.NewWithoutDocs;
        shouldShowBanner = canShowBanner;
    }

    if (state === EmployerState.New && hasDocuments) {
        employerVerificationState = EmployerVerificationState.NewWithDocs;
        shouldShowBanner = canShowBanner && !newWithDocsBannerHasClosed && !newWithDocsBannerShownCount;
        setBannerHasClosed = () => (!isSudo ? setNewWithDocsBannerHasClosed(true) : undefined);

        setBannerShownCount = () =>
            !isSudo
                ? setNewWithDocsBannerShownCount(Number(newWithDocsBannerShownCount) + 1, {
                      shouldUpdateValueInStore: false,
                  })
                : undefined;
    }

    if (state === EmployerState.NotVerified && !hasDocuments) {
        employerVerificationState = EmployerVerificationState.NotVerifiedWithoutDocs;
        shouldShowBanner =
            canShowBanner &&
            !notVerifiedWithoutDocsBannerHasClosed &&
            Number(notVerifiedWithoutDocsBannerShownCount) <= 3;
        setBannerHasClosed = () => (!isSudo ? setNotVerifiedWithoutDocsBannerHasClosed(true) : undefined);

        setBannerShownCount = () =>
            !isSudo
                ? setNotVerifiedWithoutDocsBannerShownCount(Number(notVerifiedWithoutDocsBannerShownCount) + 1, {
                      shouldUpdateValueInStore: false,
                  })
                : undefined;
    }

    if (state === EmployerState.NotVerified && hasDocuments) {
        employerVerificationState = EmployerVerificationState.NotVerifiedWithDocs;
        shouldShowBanner = false;
    }

    return {
        shouldShowIcon: candShowIcon,
        shouldShowBanner,
        isUnverifiedHhRuEmployer: state === EmployerState.New || state === EmployerState.NotVerified,
        employerVerificationState,
        setBannerHasClosed,
        setBannerShownCount,
        employerId,
        employerState: state,
    };
};
