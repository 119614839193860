import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import Analytics from '@hh.ru/analytics-js';
import Button, { ButtonAppearance, ButtonKind } from 'bloko/blocks/button';
import HoverTip from 'bloko/blocks/drop/Tip/HoverTip';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import Modal, { ModalError, ModalFooter, ModalHeader, ModalTitle } from 'bloko/blocks/modal';
import Notification, { NotificationKind } from 'bloko/blocks/notificationManager/Notification';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Form from 'src/components/Form';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { runLoyaltySurvey } from 'src/models/loyaltySurvey';
import { ComplexUserNotificationsTemplateKey, deleteUserNotification } from 'src/models/userNotifications';
import { fetcher } from 'src/utils/fetcher';

import StepHeader from 'src/components/LoyaltySurveyModal/StepHeader/StepHeader';
import Steps from 'src/components/LoyaltySurveyModal/Steps';
import {
    Answer,
    DEFAULT_ANSWER_CONFIG,
    FORM_STEPS_ARRAY,
    FormSteps,
    MultipleAnswer,
    RECOMMENDATION_PROBABILITY_MAX_VALUE,
    RECOMMENDATION_PROBABILITY_MIN_IS_LESS_THEN_VALUE,
    SKIP_ANSWER_VALUE,
    SurveyResultType,
} from 'src/components/LoyaltySurveyModal/constants';
import { calculatePassedSteps, findPosition } from 'src/components/LoyaltySurveyModal/utils';

import styles from './loyalty-survey-modal.less';

const TrlKeys = {
    button: 'notification.employer.loyaltySurvey.button',
    loadError: 'notification.employer.loyaltySurvey.questionsLoadingError',
    saveError: 'notification.employer.loyaltySurvey.answersSaveError',
    thankYou: 'notification.employer.loyaltySurvey.thankYou',
    submit: 'surveys.loyalty.popup.submit',
    back: 'surveys.loyalty.popup.back',
    next: 'surveys.loyalty.popup.next',
    skip: 'surveys.loyalty.popup.skip',
    counterPart: 'surveys.loyalty.popup.counterPart',
    popupHeader: 'surveys.loyalty.popup.header',
    droptip: 'surveys.loyalty.popup.droptip',
    header: {
        stepsRadio: {
            [FormSteps.RatingRadioWithText]: 'surveys.loyalty.MAIN_SERVICES.popup.steps.1.header',
            [FormSteps.PreferenceRadioWithText]: 'surveys.loyalty.MAIN_SERVICES.popup.steps.2.header',
            [FormSteps.RecommendRadioWithNum]: 'surveys.loyalty.MAIN_SERVICES.popup.steps.3.header',
            [FormSteps.ProtectedPDRadioWithNum]:
                'surveys.loyalty.MAIN_SERVICES.popup.steps.protectedPersonalData.header',
            [FormSteps.NonTransferablePDRadioWithNum]:
                'surveys.loyalty.MAIN_SERVICES.popup.steps.nonTransferablePersonalData.header',
        },
        stepText: {
            max: 'surveys.loyalty.MAIN_SERVICES.popup.steps.4.question.option.1',
            min: 'surveys.loyalty.MAIN_SERVICES.popup.steps.4.question.option.2',
        },
        stepCheckbox: 'surveys.loyalty.MAIN_SERVICES.popup.steps.proposedImprovements.header',
    },
    steps: {
        [FormSteps.RatingRadioWithText]: {
            '1': 'surveys.loyalty.popup.steps.1.1',
            '2': 'surveys.loyalty.popup.steps.1.2',
            '3': 'surveys.loyalty.popup.steps.1.3',
            '4': 'surveys.loyalty.popup.steps.1.4',
            '5': 'surveys.loyalty.popup.steps.1.5',
        },
        [FormSteps.PreferenceRadioWithText]: {
            '1': 'surveys.loyalty.popup.steps.2.1',
            '2': 'surveys.loyalty.popup.steps.2.2',
            '3': 'surveys.loyalty.popup.steps.2.3',
            '4': 'surveys.loyalty.popup.steps.2.4',
            '5': 'surveys.loyalty.popup.steps.2.5',
        },
        [FormSteps.ImprovementsCheckboxes]: {
            '1': 'surveys.loyalty.popup.steps.proposedImprovements.1',
            '2': 'surveys.loyalty.popup.steps.proposedImprovements.2',
            '3': 'surveys.loyalty.popup.steps.proposedImprovements.3',
            '4': 'surveys.loyalty.popup.steps.proposedImprovements.4',
            '5': 'surveys.loyalty.popup.steps.proposedImprovements.5',
            '6': 'surveys.loyalty.popup.steps.proposedImprovements.6',
            '7': 'surveys.loyalty.popup.steps.proposedImprovements.7',
            '8': 'surveys.loyalty.popup.steps.proposedImprovements.8',
            '9': 'surveys.loyalty.popup.steps.proposedImprovements.9',
            '10': 'surveys.loyalty.popup.steps.proposedImprovements.10',
            '11': 'surveys.loyalty.popup.steps.proposedImprovements.11',
            '12': 'surveys.loyalty.popup.steps.proposedImprovements.12',
            '13': 'surveys.loyalty.popup.steps.proposedImprovements.13',
            '14': 'surveys.loyalty.popup.steps.proposedImprovements.14',
            '15': 'surveys.loyalty.popup.steps.proposedImprovements.15',
            '16': 'surveys.loyalty.popup.steps.proposedImprovements.16',
            '17': 'surveys.loyalty.popup.steps.proposedImprovements.17',
            '18': 'surveys.loyalty.popup.steps.proposedImprovements.18',
            '19': 'surveys.loyalty.popup.steps.proposedImprovements.19',
            '20': 'surveys.loyalty.popup.steps.proposedImprovements.20',
        },
    },
};

const Features = {
    enabledNewNpsQuestions: 'loyalty.survey.enabled.new_nps_questions',
};

const SURVEY_URL = '/surveys/loyalty';
const SURVEY_FINISH_URL = '/surveys/loyalty/finish';

declare global {
    interface FetcherGetApi {
        [SURVEY_URL]: {
            queryParams: { sid: number };
            response: SurveyResultType;
        };
    }
    interface FetcherPostApi {
        [SURVEY_URL]: {
            queryParams: void;
            body: { answers: string; sid: number };
            response: SurveyResultType;
        };
        [SURVEY_FINISH_URL]: {
            queryParams: void;
            body: { sid: number };
            response: void;
        };
    }
}

const LoyaltySurveyModal: TranslatedComponent = ({ trls }) => {
    const enabledNewNpsQuestions = useSelector((state) => state.features[Features.enabledNewNpsQuestions]) as string;
    const params = useSelector(({ loyaltySurvey }) => loyaltySurvey);
    const userNotifications = useSelector(({ userNotifications }) => userNotifications);
    const dispatch = useDispatch();

    const [modalState, setModalState] = useState({
        showError: false,
        loadError: false,
        saveError: false,
        successSave: false,
        show: false,
        step: 0,
        disableSubmit: false,
    });

    const [surveyResult, setSurveyResult] = useState<SurveyResultType>({});

    const formRef = useRef<HTMLFormElement>(null);
    const activatorRef = useRef(null);

    const formSteps = useMemo(() => {
        const enabledSteps = new Set(enabledNewNpsQuestions?.split(',') ?? []);
        return [
            ...FORM_STEPS_ARRAY.slice(0, 2),
            enabledSteps.has(FormSteps.ImprovementsCheckboxes) && FormSteps.ImprovementsCheckboxes,
            ...FORM_STEPS_ARRAY.slice(-2),
            enabledSteps.has(FormSteps.ProtectedPDRadioWithNum) && FormSteps.ProtectedPDRadioWithNum,
            enabledSteps.has(FormSteps.NonTransferablePDRadioWithNum) && FormSteps.NonTransferablePDRadioWithNum,
        ].filter((step): step is FormSteps => step !== false);
    }, [enabledNewNpsQuestions]);
    const finalStep = formSteps.length - 1;

    const positions = useMemo(
        () => ({
            [FormSteps.PreferenceRadioWithText]: findPosition(FormSteps.PreferenceRadioWithText, formSteps),
            [FormSteps.RatingRadioWithText]: findPosition(FormSteps.RatingRadioWithText, formSteps),
            [FormSteps.ImprovementsCheckboxes]: findPosition(FormSteps.ImprovementsCheckboxes, formSteps),
            [FormSteps.RecommendRadioWithNum]: findPosition(FormSteps.RecommendRadioWithNum, formSteps),
            [FormSteps.ReasonsText]: findPosition(FormSteps.ReasonsText, formSteps),
            [FormSteps.ProtectedPDRadioWithNum]: findPosition(FormSteps.ProtectedPDRadioWithNum, formSteps),
            [FormSteps.NonTransferablePDRadioWithNum]: findPosition(FormSteps.NonTransferablePDRadioWithNum, formSteps),
        }),
        [formSteps]
    );

    const answers: (Answer | MultipleAnswer)[] = useMemo(
        () => formSteps.map((variant) => surveyResult[variant] ?? DEFAULT_ANSWER_CONFIG[variant]),
        [formSteps, surveyResult]
    );

    const titleText = useMemo(() => {
        const indexStep = formSteps[modalState.step];
        if (indexStep === FormSteps.ReasonsText) {
            const answerCode = surveyResult[FormSteps.RecommendRadioWithNum]?.answerCode;
            return (
                (answerCode &&
                    trls[
                        TrlKeys.header.stepText[
                            parseInt(answerCode, RECOMMENDATION_PROBABILITY_MAX_VALUE) >=
                            RECOMMENDATION_PROBABILITY_MIN_IS_LESS_THEN_VALUE
                                ? 'max'
                                : 'min'
                        ]
                    ]) ||
                ''
            );
        }
        if (indexStep === FormSteps.ImprovementsCheckboxes) {
            return trls[TrlKeys.header.stepCheckbox];
        }
        return trls[TrlKeys.header.stepsRadio[indexStep]];
    }, [formSteps, modalState.step, surveyResult, trls]);

    const answersText: Record<string, string> | undefined = useMemo(() => {
        const currentStep = formSteps[modalState.step];
        if (
            currentStep === FormSteps.PreferenceRadioWithText ||
            currentStep === FormSteps.RatingRadioWithText ||
            currentStep === FormSteps.ImprovementsCheckboxes
        ) {
            const stepTranslations = TrlKeys.steps[currentStep];
            return Object.keys(stepTranslations).reduce(
                (acc, variant) => ({
                    ...acc,
                    [variant]: trls[stepTranslations[variant as keyof typeof stepTranslations]],
                }),
                {}
            );
        }
        return undefined;
    }, [formSteps, modalState.step, trls]);

    const finishSurvey = useCallback(
        async (sid: number) => {
            userNotifications.forEach((el) => {
                if (ComplexUserNotificationsTemplateKey.EmployerLoyaltySurvey === el.templateKey) {
                    dispatch(deleteUserNotification(el.id));
                }
            });

            try {
                await fetcher.postFormData(SURVEY_FINISH_URL, {
                    sid,
                });
            } catch (_) {
                setModalState((prev) => ({ ...prev, saveError: true }));
                return;
            }

            Analytics.sendEvent('employer', 'finish-loyalty-survey', params.type);
            setModalState((prev) => ({ ...prev, show: false, successSave: true }));
        },
        [dispatch, params.type, userNotifications]
    );

    const fetchSurvey = useCallback(async () => {
        if (params.id === undefined || !params.runSurvey) {
            return;
        }

        let survey: SurveyResultType | undefined;
        const controller = new AbortController();
        try {
            survey = await fetcher.get(SURVEY_URL, {
                signal: controller.signal,
                params: {
                    sid: params.id,
                },
            });
        } catch (_) {
            setModalState((prev) => ({ ...prev, loadError: true }));
            return;
        }

        // временно фильтруем результаты
        survey = Object.fromEntries(Object.entries(survey).filter(([key]) => formSteps.includes(key)));

        setSurveyResult(survey);
        const passedStepsCount = calculatePassedSteps(survey, positions);
        setModalState((prev) => ({
            ...prev,
            step: Math.min(finalStep, passedStepsCount),
            show: true,
        }));
        Analytics.sendEvent('employer', 'open-loyalty-survey-popup', params.type);
    }, [finalStep, formSteps, params.id, params.runSurvey, params.type, positions]);

    const saveAnswers = useCallback(
        async (useFormData: boolean, stepChange: number, shouldFinish = false) => {
            if (!params.id || formRef.current === null) {
                return;
            }

            setModalState((prev) => ({ ...prev, disableSubmit: true }));

            let answer: SurveyResultType = {};
            if (useFormData) {
                const data = new FormData(formRef.current);
                const stepVariant = [...data.keys()].find((el) => formSteps.includes(el)) as FormSteps;
                if (stepVariant) {
                    if (stepVariant === FormSteps.ImprovementsCheckboxes) {
                        const answerCodes =
                            (data.getAll(
                                stepVariant
                            ) as (keyof (typeof TrlKeys.steps)[FormSteps.ImprovementsCheckboxes])[]) ||
                            DEFAULT_ANSWER_CONFIG[stepVariant].answers;
                        const multipleAnswers = answerCodes.map((code) => {
                            return {
                                answerCode: code,
                                answerText: answersText?.[code] || '',
                            };
                        });
                        answer = {
                            [stepVariant]: {
                                question: titleText,
                                answers: multipleAnswers,
                            },
                        };
                    } else {
                        let answerCode =
                            (data.get(stepVariant) as string) || DEFAULT_ANSWER_CONFIG[stepVariant].answerCode;
                        let answerText = '';
                        if (stepVariant === FormSteps.ReasonsText) {
                            answerText = answerCode === SKIP_ANSWER_VALUE ? '' : answerCode;
                            answerCode = '';
                        }
                        if (
                            stepVariant === FormSteps.RecommendRadioWithNum ||
                            stepVariant === FormSteps.ProtectedPDRadioWithNum ||
                            stepVariant === FormSteps.NonTransferablePDRadioWithNum
                        ) {
                            answerText = answerCode;
                        }
                        if (
                            stepVariant === FormSteps.PreferenceRadioWithText ||
                            stepVariant === FormSteps.RatingRadioWithText
                        ) {
                            answerText = answersText?.[answerCode] || '';
                        }
                        answer = {
                            [stepVariant]: {
                                question: titleText,
                                ...(answerCode && { answerCode }),
                                answerText,
                            },
                        };
                    }
                    setSurveyResult({ ...surveyResult, ...answer });
                }
            } else {
                if (formSteps[modalState.step] === FormSteps.ImprovementsCheckboxes) {
                    answer = {
                        [formSteps[modalState.step]]: {
                            question: titleText,
                            answers: [{ answerCode: SKIP_ANSWER_VALUE, answerText: trls[TrlKeys.skip] }],
                        },
                    };
                } else {
                    let answerText = trls[TrlKeys.skip];
                    if (formSteps[modalState.step] === FormSteps.ReasonsText) {
                        answerText = '';
                    }
                    answer = {
                        [formSteps[modalState.step]]: {
                            question: titleText,
                            answerCode: SKIP_ANSWER_VALUE,
                            answerText,
                        },
                    };
                }
                setSurveyResult({ ...surveyResult, ...answer });
            }

            try {
                await fetcher.postFormData(SURVEY_URL, {
                    sid: params.id,
                    answers: JSON.stringify({ ...surveyResult, ...answer }),
                });
            } catch (_) {
                setModalState((prev) => ({ ...prev, showError: true }));
                return;
            } finally {
                setModalState((prev) => ({ ...prev, disableSubmit: false }));
            }

            if (shouldFinish) {
                await finishSurvey(params.id);
                return;
            }

            const newStep = modalState.step + stepChange;
            if (newStep >= 0 && newStep <= finalStep) {
                setModalState((prev) => ({ ...prev, step: modalState.step + stepChange }));
            }
        },
        [params.id, modalState.step, finalStep, formSteps, surveyResult, titleText, answersText, trls, finishSurvey]
    );

    const closeModal = useCallback(() => {
        void saveAnswers(true, 0);
        dispatch(runLoyaltySurvey(false));
        setModalState((prev) => ({ ...prev, show: false }));
    }, [dispatch, saveAnswers]);

    const handleNext = () => {
        let stepChange = 1;
        const recommend = surveyResult[FormSteps.RecommendRadioWithNum];
        if (recommend) {
            const isCurrentStepReasons = formSteps[modalState.step] === FormSteps.ReasonsText;
            const isImprovementsEnabled = formSteps.includes(FormSteps.ImprovementsCheckboxes);
            if (isCurrentStepReasons && isImprovementsEnabled && Number(recommend.answerCode) > 8) {
                stepChange = 2;
            }
        }
        setModalState((prev) => ({ ...prev, showError: false }));
        void saveAnswers(true, stepChange, modalState.step === finalStep);
    };

    const handlePrevious = () => {
        let stepChange = -1;
        const recommend = surveyResult[FormSteps.RecommendRadioWithNum];
        if (recommend) {
            const isCurrentStepRating = formSteps[modalState.step] === FormSteps.RatingRadioWithText;
            const isRecommendSkip = recommend.answerCode === SKIP_ANSWER_VALUE;
            const isImprovementsEnabled = formSteps.includes(FormSteps.ImprovementsCheckboxes);
            const isReasonsSkip = surveyResult[FormSteps.ReasonsText]?.answerCode === SKIP_ANSWER_VALUE;
            const isCurrentRatingAndImprovementsEnabledAndRecommendMoreThen8 =
                isCurrentStepRating && isImprovementsEnabled && Number(recommend.answerCode) > 8;
            if (
                (isCurrentStepRating && isRecommendSkip) ||
                (isCurrentRatingAndImprovementsEnabledAndRecommendMoreThen8 && !isReasonsSkip)
            ) {
                stepChange = -2;
            }
            if (
                (isCurrentStepRating && isImprovementsEnabled && isRecommendSkip) ||
                (isCurrentRatingAndImprovementsEnabledAndRecommendMoreThen8 && isReasonsSkip)
            ) {
                stepChange = -3;
            }
        }

        void saveAnswers(true, stepChange);
    };

    const handleSkip = () => {
        let stepChange = 1;
        const recommend = surveyResult[FormSteps.RecommendRadioWithNum];
        const isCurrentStepReasons = formSteps[modalState.step] === FormSteps.ReasonsText;
        const isRecommendStep = formSteps[modalState.step] === FormSteps.RecommendRadioWithNum;
        const isImprovementsEnabled = formSteps.includes(FormSteps.ImprovementsCheckboxes);
        if (isRecommendStep || (isImprovementsEnabled && isCurrentStepReasons && Number(recommend?.answerCode) > 8)) {
            stepChange = 2;
        }
        if (isRecommendStep && isImprovementsEnabled) {
            stepChange = 3;
        }
        void saveAnswers(false, stepChange, modalState.step === finalStep);
    };

    useEffect(() => {
        void fetchSurvey();
    }, [fetchSurvey]);

    useEffect(() => {
        const currentStep = surveyResult[formSteps[modalState.step]];
        if (
            !currentStep ||
            ('answerCode' in currentStep &&
                (currentStep?.answerCode === SKIP_ANSWER_VALUE ||
                    (!currentStep?.answerCode && formSteps[modalState.step] !== FormSteps.ReasonsText))) ||
            ('answerText' in currentStep && !currentStep?.answerText) ||
            ('answers' in currentStep &&
                (currentStep.answers?.[0]?.answerCode === SKIP_ANSWER_VALUE || !currentStep.answers?.[0]?.answerCode))
        ) {
            setModalState((prev) => ({ ...prev, disableSubmit: true }));
        } else {
            setModalState((prev) => ({ ...prev, disableSubmit: false }));
        }
    }, [surveyResult, modalState.step, formSteps]);

    if (modalState.loadError) {
        return (
            <Notification kind={NotificationKind.Error} autoClose>
                {trls[TrlKeys.loadError]}
            </Notification>
        );
    }

    if (modalState.saveError) {
        return (
            <Notification kind={NotificationKind.Error} autoClose>
                {trls[TrlKeys.saveError]}
            </Notification>
        );
    }

    if (modalState.successSave) {
        return (
            <Notification kind={NotificationKind.Ok} autoClose>
                {trls[TrlKeys.thankYou]}
            </Notification>
        );
    }

    const submitButton = (
        <div className={styles.loyaltySurveyModalFooterTipHelper} ref={activatorRef}>
            <Button
                disabled={modalState.disableSubmit}
                data-qa="loyalty-survey-next-question"
                stretched
                kind={ButtonKind.Primary}
                onClick={handleNext}
            >
                {trls[modalState.step !== finalStep ? TrlKeys.next : TrlKeys.submit]}
            </Button>
        </div>
    );

    return (
        <Modal onClose={closeModal} visible={modalState.show}>
            <ModalHeader>
                <ModalTitle>{trls[TrlKeys.popupHeader]}</ModalTitle>
            </ModalHeader>
            <StepHeader step={modalState.step} total={formSteps.length} title={titleText} surveyResult={surveyResult} />
            <div className={styles.loyaltySurveyModalBody} data-qa="loyalty-survey-popup-body">
                <Form
                    method="POST"
                    action={SURVEY_URL}
                    ref={formRef}
                    onChange={() => {
                        setModalState((prev) => ({ ...prev, disableSubmit: false }));
                    }}
                >
                    <div data-qa="loyalty-survey-step">
                        <Steps
                            step={modalState.step}
                            answers={answers}
                            positions={positions}
                            answersText={answersText}
                            formSteps={formSteps}
                        />
                    </div>
                </Form>
            </div>
            <ModalError visible={modalState.showError}>{trls[TrlKeys.saveError]}</ModalError>
            <ModalFooter>
                <div className={styles.loyaltySurveyModalFooter}>
                    <div className={styles.loyaltySurveyModalFooterLink}>
                        <Link
                            appearance={LinkAppearance.Pseudo}
                            data-qa="loyalty-survey-skip-question"
                            onClick={handleSkip}
                        >
                            {trls[TrlKeys.skip]}
                        </Link>
                    </div>
                    <div className={styles.loyaltySurveyModalFooterButtonsWrapper}>
                        {modalState.step > 0 && (
                            <Button
                                data-qa="loyalty-survey-previous-question"
                                stretched
                                appearance={ButtonAppearance.Outlined}
                                onClick={handlePrevious}
                            >
                                {trls[TrlKeys.back]}
                            </Button>
                        )}
                        {modalState.disableSubmit && (
                            <HoverTip render={() => trls[TrlKeys.droptip]} activatorRef={activatorRef}>
                                {submitButton}
                            </HoverTip>
                        )}
                        {!modalState.disableSubmit && submitButton}
                    </div>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default translation(LoyaltySurveyModal);
