export const SKIP_ANSWER_VALUE = '99';
export const DEFAULT_ANSWER = {
    question: '',
    answerCode: SKIP_ANSWER_VALUE,
    answerText: '',
};
export const DEFAULT_MULTIPLE_ANSWER = {
    question: '',
    answers: [],
};

export interface Answer {
    question: string;
    answerCode: string;
    answerText: string;
}

export interface MultipleAnswerItem {
    answerCode: string | number;
    answerText: string;
}

export interface MultipleAnswer {
    question: string;
    answers: MultipleAnswerItem[];
}

export const RECOMMENDATION_PROBABILITY_MAX_VALUE = 10;
export const RECOMMENDATION_PROBABILITY_MIN_IS_LESS_THEN_VALUE = 9;
export enum FormSteps {
    RecommendRadioWithNum = 'recommendationProbability',
    ReasonsText = 'recommendationReasons',
    ImprovementsCheckboxes = 'proposedImprovements',
    RatingRadioWithText = 'overallRating',
    PreferenceRadioWithText = 'preferenceProbability',
    ProtectedPDRadioWithNum = 'protectedPersonalData',
    NonTransferablePDRadioWithNum = 'nonTransferablePersonalData',
}

export interface SurveyResultType {
    [FormSteps.RecommendRadioWithNum]?: Answer;
    [FormSteps.ReasonsText]?: Answer;
    [FormSteps.ImprovementsCheckboxes]?: MultipleAnswer;
    [FormSteps.RatingRadioWithText]?: Answer;
    [FormSteps.PreferenceRadioWithText]?: Answer;
    [FormSteps.ProtectedPDRadioWithNum]?: Answer;
    [FormSteps.NonTransferablePDRadioWithNum]?: Answer;
}

export const FORM_STEPS_ARRAY = [
    FormSteps.RecommendRadioWithNum,
    FormSteps.ReasonsText,
    FormSteps.RatingRadioWithText,
    FormSteps.PreferenceRadioWithText,
];

export const DEFAULT_ANSWER_CONFIG = {
    [FormSteps.RecommendRadioWithNum]: DEFAULT_ANSWER,
    [FormSteps.ReasonsText]: DEFAULT_ANSWER,
    [FormSteps.ImprovementsCheckboxes]: DEFAULT_MULTIPLE_ANSWER,
    [FormSteps.RatingRadioWithText]: DEFAULT_ANSWER,
    [FormSteps.PreferenceRadioWithText]: DEFAULT_ANSWER,
    [FormSteps.ProtectedPDRadioWithNum]: DEFAULT_ANSWER,
    [FormSteps.NonTransferablePDRadioWithNum]: DEFAULT_ANSWER,
};
