import { ReactNode, useRef } from 'react';
import classnames from 'classnames';

import buttonClick from '@hh.ru/analytics-js-events/build/xhh/common/main/button_click';
import { GridColumn, GridLayout, Link, useBreakpoint, VSpacing } from '@hh.ru/magritte-ui';
import { LocationFilledSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import paths from 'src/app/routePaths';
import ApplicantActivityCard from 'src/components/Applicant/ActivityCard';
import Form from 'src/components/Form';
import SearchQueries from 'src/components/ResumeAdvancedSearch/SearchQueries';
import MultisearchControllers from 'src/components/ResumeAdvancedSearch/SearchQueries/Magritte/MultisearchControllers';
import SupernovaSearch from 'src/components/SupernovaSearch';
import AreaSwitcherModal from 'src/components/SupernovaSearch/AreaSwitcherModal';
import HiddenFields from 'src/components/SupernovaSearch/HiddenFields';
import SearchTabs from 'src/components/SupernovaSearch/SearchTabs';
import { useSearchBaseState } from 'src/components/SupernovaSearch/useSearchBaseState';
import { useSearchNavItemProps } from 'src/components/SupernovaSearch/useSearchNavItemProps';
import SearchViewSwitcher from 'src/components/VacancySearchContent/components/SearchViewSwitcher';
import translation from 'src/components/translation';
import { useHandleOpenGeoSwitcher } from 'src/hooks/useHandleOpenGeoSwitcher';
import { useIsRedesignGeoExpActive } from 'src/hooks/useIsRedesignGeoExpActive';
import { useOnOffState } from 'src/hooks/useOnOffState';
import { useSelector } from 'src/hooks/useSelector';

import styles from './styles.less';

const TrlKeys = {
    areaSwitcherModalTrigger: 'index.dashboard.in',
};

interface Props {
    afterMenuContent?: ReactNode;
    searchExpanded?: boolean;
}

const NaviSearch: TranslatedComponent<Props> = ({ afterMenuContent, searchExpanded, trls }) => {
    const showResumeSearchConditions = useSelector(({ showResumeSearchConditions }) => showResumeSearchConditions);
    const supernovaSearchLargeXS = useSelector(({ supernovaSearchView }) => supernovaSearchView.largeXS);
    const supernovaSearchExpanded = useSelector(({ supernovaSearchView }) => supernovaSearchView.expanded);
    const searchBaseState = useSearchBaseState();
    const { supernovaSearchName, isEmployerSearch, searchName } = searchBaseState;
    const addQueryRef = useRef<() => void>();
    const formRef = useRef<HTMLFormElement>(null);
    const searchProps = useSearchNavItemProps(searchName);
    const [areaSwitchVisible, setAreaSwitchVisible, setAreaSwitchHidden] = useOnOffState(false);
    const { translations } = useSelector(({ supernovaSearchArea }) => supernovaSearchArea);
    const { pathname } = useSelector(({ router }) => router.location);
    const { isMobile } = useBreakpoint();
    const openGeoSwitcher = useHandleOpenGeoSwitcher(setAreaSwitchVisible);
    const isRedesignGeoExpActive = useIsRedesignGeoExpActive();

    if (showResumeSearchConditions) {
        return (
            <GridLayout>
                <GridColumn xs={4} s={8} m={12}>
                    <VSpacing default={24} gteS={40} />
                    {afterMenuContent && (
                        <>
                            {afterMenuContent}
                            <VSpacing default={12} gteS={24} />
                        </>
                    )}
                    <Form action={searchProps?.action} method="GET" ref={formRef}>
                        <HiddenFields searchName={searchName} />
                        <SearchQueries isNaviSearch addQueryRef={addQueryRef} formRef={formRef} isMagritte />
                        <MultisearchControllers searchBaseState={searchBaseState} addQueryRef={addQueryRef} />
                    </Form>
                    <VSpacing default={12} />
                </GridColumn>
            </GridLayout>
        );
    }

    const hideSearchTabs = supernovaSearchName === null || isEmployerSearch;
    const showPageControllers = supernovaSearchName && !isEmployerSearch && pathname !== paths.vacancySearchMap;
    const handleClickRegion = () => {
        openGeoSwitcher();
        buttonClick({ buttonName: 'vacancy_search_region' });
    };

    return (
        <div
            className={classnames(styles.supernovaNaviSearch, {
                [styles.supernovaNaviSearchExpanded]: supernovaSearchExpanded || supernovaSearchName || searchExpanded,
                [styles.supernovaNaviSearchHiddenXs]: supernovaSearchLargeXS,
            })}
        >
            <GridLayout>
                <GridColumn xs={4} s={8} m={12}>
                    <VSpacing default={24} gteS={40} />
                    {afterMenuContent && (
                        <>
                            {afterMenuContent}
                            <VSpacing default={12} gteS={24} />
                        </>
                    )}
                    {!isMobile && !hideSearchTabs && (
                        <>
                            <SearchTabs {...searchBaseState} />
                            <VSpacing default={12} gteS={24} />
                        </>
                    )}
                    <SupernovaSearch {...searchBaseState} />
                    {showPageControllers && isMobile && (
                        <>
                            <VSpacing default={12} />
                            <div className={styles.supernovaNaviSearchRegion}>
                                <div className={styles.regionLinkWrapper}>
                                    <Link
                                        Element="a"
                                        iconLeft={<LocationFilledSize16 />}
                                        data-qa="vacancy-search-region-activator"
                                        onClick={handleClickRegion}
                                        inline
                                    >
                                        {trls[TrlKeys.areaSwitcherModalTrigger]}
                                        {isRedesignGeoExpActive
                                            ? translations.geoExpName || translations.area
                                            : translations.area}
                                    </Link>
                                </div>
                                <AreaSwitcherModal visible={areaSwitchVisible} onClose={setAreaSwitchHidden} />
                                <SearchViewSwitcher />
                            </div>
                            <VSpacing default={20} />
                            <ApplicantActivityCard variant="compact" iconType="info" />
                        </>
                    )}
                    <VSpacing default={12} />
                </GridColumn>
            </GridLayout>
        </div>
    );
};

export default translation(NaviSearch);
