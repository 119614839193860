import { useSelector } from 'src/hooks/useSelector';
import { MessengerType } from 'src/models/messengers';
import { SupernovaUserType } from 'src/models/supernovaUserType';
import { UserType } from 'src/models/userType';

const MESSENGERS = [
    {
        href: 'https://telegram.me/hh_applicant_bot',
        messenger: MessengerType.Telegram,
    },
];

const EMPLOYER_MESSENGERS = [
    {
        href: 'https://telegram.me/hh_ru_bot',
        messenger: MessengerType.Telegram,
    },
];

const NEW_EMPLOYER_MESSENGERS = [
    {
        href: 'https://telegram.me/reg_hh_bot',
        messenger: MessengerType.Telegram,
    },
];

const useSupportMessengers = () => {
    const employerStatus = useSelector(({ employerStatus }) => employerStatus);
    const messengers = useSelector(({ messengers }) => messengers);
    const userType = useSelector(({ userType }) => userType);
    const supernovaUserType = useSelector(({ supernovaUserType }) => supernovaUserType);

    let messengersToRender;
    if (userType === UserType.Employer || supernovaUserType === SupernovaUserType.Employer) {
        messengersToRender = EMPLOYER_MESSENGERS;
        if (employerStatus.state === 'NEW') {
            const whatsapp = messengers.find(({ messengerType }) => messengerType === MessengerType.Whatsapp);
            if (whatsapp) {
                messengersToRender = [
                    ...NEW_EMPLOYER_MESSENGERS,
                    {
                        href: `https://wa.me/${whatsapp.country}${whatsapp.city}${whatsapp.number}`,
                        messenger: MessengerType.Whatsapp,
                    },
                ];
            }
        }
    } else {
        messengersToRender = MESSENGERS;
    }
    return messengersToRender;
};

export { useSupportMessengers };
