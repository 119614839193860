import React, { FC, useContext, useEffect, useMemo, useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { useBreakpoint, Breakpoint } from '@hh.ru/magritte-ui';
import { ChevronDownOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { Breakpoint as BlokoBreakpoint } from 'bloko/common/media';

import { useOnOffState } from 'src/hooks/useOnOffState';
import { NavItem } from 'src/models/supernovaNaviMenu';

import DropdownContent from 'src/components/SupernovaMainMenu/DropdownContent';
import SupernovaActionLink from 'src/components/SupernovaMainMenu/SupernovaActionLink';
import SupernovaDropdown from 'src/components/SupernovaMainMenu/SupernovaDropdown';
import SupernovaDropdownOption from 'src/components/SupernovaMainMenu/SupernovaDropdownOption';
import SupernovaDropdownSection from 'src/components/SupernovaMainMenu/SupernovaDropdownSection';
import SupernovaIcon, { SupernovaIconView } from 'src/components/SupernovaMainMenu/SupernovaIcon';
import SupernovaMagritteDropdown from 'src/components/SupernovaMainMenu/SupernovaMagritteDropdown';
import SupernovaNaviItem from 'src/components/SupernovaMainMenu/SupernovaNaviItem';
import { useIsAuthorizedRedesign } from 'src/components/SupernovaMainMenu/hooks/useIsAuthorizedRedesign';
import isRedesignActiveContext from 'src/components/SupernovaMainMenu/isRedesignActiveContext';

const FULL_WIDTH_BREAKPOINTS = [BlokoBreakpoint.XS, BlokoBreakpoint.S, BlokoBreakpoint.M];
const VISIBLE_ON = [Breakpoint.M];
const ANON_VISIBLE_ON = [Breakpoint.S];

const MoreItems: FC<NavItem> = ({ name, subItems, translations, trackElementShown, trackClicks, analytics }) => {
    const activatorRef = useRef<HTMLButtonElement>(null);
    const [currentTab, setCurrentTab] = React.useState<string>();
    const ref = useRef<HTMLDivElement>(null);
    const isRedesignActive = useContext(isRedesignActiveContext);
    const [isBottomSheetVisible, openBottomSheet, closeBottomSheet] = useOnOffState(false);
    const isAuthorizedRedesign = useIsAuthorizedRedesign();

    const { isM, isS } = useBreakpoint();

    const isMenuItemVisible = useMemo(
        () => (isRedesignActive && !isAuthorizedRedesign ? isS : isM),
        [isAuthorizedRedesign, isM, isRedesignActive, isS]
    );

    const render = React.useCallback(
        (onClose: () => void) => {
            return (
                <div className="supernova-dropdown-menu supernova-dropdown-menu_more-items">
                    <div className="supernova-dropdown-menu-tabs">
                        <SupernovaDropdownSection>
                            {subItems?.map((item) => (
                                <SupernovaDropdownOption
                                    key={item.name}
                                    name={item.name}
                                    onClick={() => {
                                        setCurrentTab(item.name);
                                    }}
                                    trackClicks={item.trackClicks}
                                    trackElementShown={item.trackElementShown}
                                    url={item.url}
                                    modalType={item.modalType}
                                    counter={item.counter}
                                    subItems={item.subItems}
                                    onClose={onClose}
                                >
                                    {item.translations.name}
                                </SupernovaDropdownOption>
                            ))}
                        </SupernovaDropdownSection>
                    </div>
                    <div className="supernova-dropdown-menu-content-wrapper">
                        {subItems
                            ?.filter((item) => item.name === currentTab)
                            .map((item) => (
                                <div className="supernova-dropdown-menu-content" key={item.name}>
                                    <DropdownContent item={item} onClose={onClose} />
                                </div>
                            ))}
                    </div>
                </div>
            );
        },
        [currentTab, subItems]
    );

    useEffect(() => {
        if (trackElementShown && ref.current && isMenuItemVisible) {
            Analytics.sendHHEventElementShown(ref.current, { name: `menu_item_${name}` });
        }
    }, [isMenuItemVisible, name, trackElementShown]);

    if (isRedesignActive || isAuthorizedRedesign) {
        return (
            <SupernovaNaviItem visibleOn={isAuthorizedRedesign ? VISIBLE_ON : ANON_VISIBLE_ON} ref={ref}>
                <SupernovaActionLink
                    ref={activatorRef}
                    iconRight={<ChevronDownOutlinedSize16 />}
                    onClick={openBottomSheet}
                    name={name}
                    trackClicks={trackClicks}
                    analytics={analytics}
                    data-qa={`mainmenu_${name}`}
                >
                    {translations.name}
                </SupernovaActionLink>
                <SupernovaMagritteDropdown
                    activatorRef={activatorRef}
                    onClose={closeBottomSheet}
                    visible={isBottomSheetVisible}
                    items={subItems || []}
                />
            </SupernovaNaviItem>
        );
    }

    return (
        <SupernovaNaviItem ref={ref} visibleOn={VISIBLE_ON}>
            <SupernovaDropdown
                activator={
                    <SupernovaIcon
                        data-qa={`mainmenu_${name}`}
                        name={name}
                        trackClicks={trackClicks}
                        view={SupernovaIconView.MoreItems}
                        reversed
                    >
                        <span className="supernova-link">{translations.name}</span>
                    </SupernovaIcon>
                }
                anchorFullHeight
                fullWidthBreakpoints={FULL_WIDTH_BREAKPOINTS}
                render={render}
            />
        </SupernovaNaviItem>
    );
};

export default MoreItems;
