import { useEffect, useMemo, useRef } from 'react';

import { SearchInput, useBreakpoint, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import GeoSwitcherPopupLoader from 'src/components/SupernovaMainMenu/GeoSwitcherPopup/GeoSwitcherPopupLoader';
import GeoSwitcherPopupRegionsList, {
    GeoSwitcherPopupRegionData,
} from 'src/components/SupernovaMainMenu/GeoSwitcherPopup/GeoSwitcherPopupRegionsList';
import { useGetTopLevelDomains } from 'src/components/SupernovaMainMenu/GeoSwitcherPopup/hooks/useGetTopLevelDomains';
import { useSearchRegions } from 'src/components/SupernovaMainMenu/GeoSwitcherPopup/hooks/useSearchRegions';

const TrlKeys = {
    placeholder: 'geoSwitcher.popup.search.placeholder',
};

const GeoSwitcherPopupContent: TranslatedComponent = ({ trls }) => {
    const { topLevelDomainsData, isLoading } = useGetTopLevelDomains();
    const { handleSearchChange, search, searchRegionsData, lastSearchQuery } = useSearchRegions();
    const searchInputRef = useRef<HTMLInputElement>(null);
    const { isMobile } = useBreakpoint();

    useEffect(() => {
        if (!isMobile) {
            searchInputRef.current?.focus();
        }
    }, [isMobile]);

    const items = useMemo<GeoSwitcherPopupRegionData[]>(() => {
        if (searchRegionsData) {
            return (
                searchRegionsData?.items.map<GeoSwitcherPopupRegionData>((item) => ({
                    href: item.href,
                    region: item.region,
                    name: item.text,
                    isOtherDomain: item.isOtherDomain,
                    topLevelDomain: item.topLevelDomain,
                    areaId: item.areaId,
                })) || []
            );
        }

        const topLevelDomain = topLevelDomainsData?.topLevelDomains?.find((domain) => domain.isCurrentDomain);

        return (
            topLevelDomain?.subdomains?.map<GeoSwitcherPopupRegionData>((domain) => ({
                href: domain.href || '',
                name: domain.name || '',
                isCurrentHost: domain.isCurrentHost,
                areaId: domain.areaId,
            })) || []
        );
    }, [searchRegionsData, topLevelDomainsData?.topLevelDomains]);

    return (
        <div>
            <SearchInput
                onChange={handleSearchChange}
                value={search}
                placeholder={trls[TrlKeys.placeholder]}
                clearable
                data-qa="geo-switcher-search"
                ref={searchInputRef}
            />
            <VSpacing xs={32} s={32} default={24} />
            {isLoading ? (
                <GeoSwitcherPopupLoader />
            ) : (
                <GeoSwitcherPopupRegionsList
                    lastSearchQuery={lastSearchQuery}
                    isPopular={!searchRegionsData}
                    items={items}
                />
            )}
        </div>
    );
};

export default translation(GeoSwitcherPopupContent);
