import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { VacanciesList } from 'src/models/employerVacancies/vacancy.types';

const ADD_FUNNEL_VACANCIES = 'ADD_FUNNEL_VACANCIES';

interface PayloadTypes {
    [ADD_FUNNEL_VACANCIES]: VacanciesList;
}

const ActionCreator = ActionCreatorHelper<PayloadTypes>();

export const addFunnelVacancies = ActionCreator(ADD_FUNNEL_VACANCIES);

export default createReducer<VacanciesList, PayloadTypes>(null, {
    [ADD_FUNNEL_VACANCIES]: (state, { payload: { list } }) => {
        return {
            ...state,
            list: [...state.list, ...list],
        };
    },
});
