import { FC, SyntheticEvent, useEffect, useState } from 'react';

import { VSpacing } from '@hh.ru/magritte-ui';

import SupportActionsLayout from 'src/components/Support/SupportActions/SupportActionsLayout';
import { useSupportChatik } from 'src/components/SupportChatPlate/useSupportChatik';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { NavItem } from 'src/models/supernovaNaviMenu';
import { UserType } from 'src/models/userType';

import HelpModalButtonLink from 'src/components/SupernovaMainMenu/HelpModal/HelpModalButtonLinks/HelpModalButtonLink';

const HelpModalButtonLinks: FC<{
    item?: NavItem;
    handleCloseModal: (event?: SyntheticEvent) => void;
    onClose?: () => void;
}> = ({ item, handleCloseModal, onClose }) => {
    const userType = useSelector(({ userType }) => userType);
    const isEmployer = UserType.Employer === userType;
    const isZP = useIsZarplataPlatform();
    const [numberOfCalculatedElements, setNumberOfCalculatedElements] = useState(0);
    const [isZpFeedbackWide, setIsZpFeedbackWide] = useState(false);

    const closeModalAndSupernovaDropdown = () => {
        onClose?.();
        handleCloseModal();
    };

    const chatik = useSupportChatik('menu_support', closeModalAndSupernovaDropdown);

    const helpModalButtonLinks = item?.subItems;

    useEffect(() => {
        if (!helpModalButtonLinks?.length) {
            return undefined;
        }

        const isSupportChatEnabled = helpModalButtonLinks.some((item) => item.name === 'supportChat');
        const isWinPlatform = navigator.userAgent.includes('Win');
        const isZpFeedback = helpModalButtonLinks.some((item) => item.name === 'zpFeedback');
        setIsZpFeedbackWide(isWinPlatform && isZpFeedback);
        setNumberOfCalculatedElements(
            helpModalButtonLinks.length -
                ((!isZP && isSupportChatEnabled && !isEmployer) || (isWinPlatform && isZpFeedback) ? 1 : 0)
        );

        return undefined;
    }, [helpModalButtonLinks, isEmployer, isZP]);

    if (!helpModalButtonLinks?.length) {
        return null;
    }

    return (
        <>
            {isEmployer && <VSpacing default={24} />}
            <SupportActionsLayout>
                {helpModalButtonLinks.map((supportAction, index) => {
                    const isEmployer = UserType.Employer === userType;
                    const isChatik = supportAction.name === 'supportChat';
                    const isWide =
                        (isChatik && !isEmployer) ||
                        isZpFeedbackWide ||
                        (!!(numberOfCalculatedElements % 2) && index === 0);
                    return <HelpModalButtonLink {...supportAction} chatik={chatik} isWide={isWide} key={index} />;
                })}
            </SupportActionsLayout>
        </>
    );
};

export default HelpModalButtonLinks;
