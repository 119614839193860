import { Button } from '@hh.ru/magritte-ui';
import { LineArrowRightOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Form from 'src/components/Form';
import { useTrackClicks } from 'src/components/SupernovaMainMenu/useTrackClicks';
import translation from 'src/components/translation';
import { NavItem } from 'src/models/supernovaNaviMenu';

import styles from './styles.less';

const TrlKeys = {
    logoff: 'employer.employerProfile.popup.logoff',
};

const LogoffButton: TranslatedComponent<NavItem> = ({ trls, url, name, trackClicks, analytics }) => {
    const handleTrackClick = useTrackClicks(name, trackClicks, analytics);

    return (
        <Form className={styles.fullwidthForm} action={url}>
            <Button
                data-qa="logoff-button"
                icon={<LineArrowRightOutlinedSize24 />}
                stretched
                onClick={handleTrackClick}
                mode="secondary"
                style="negative"
                size="large"
                type="submit"
            >
                {trls[TrlKeys.logoff]}
            </Button>
        </Form>
    );
};

export default translation(LogoffButton);
