import { useState, useLayoutEffect } from 'react';

export function useIsClient(): boolean {
    const [isClient, setIsClient] = useState(false);
    useLayoutEffect(() => {
        setIsClient(true);
    }, []);

    return isClient;
}
