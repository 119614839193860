import React, { forwardRef, ForwardRefRenderFunction, useContext } from 'react';
import classnames from 'classnames';

import { Link, ComponentWithBadge, Text } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';

import SupernovaBadge from 'src/components/SupernovaMainMenu/SupernovaBadge';
import isMenuLight from 'src/components/SupernovaMainMenu/isMenuLight';
import isRedesignActiveContext from 'src/components/SupernovaMainMenu/isRedesignActiveContext';
import isRedesignAnonymouContext from 'src/components/SupernovaMainMenu/isRedesignAnonymouContext';
import { TrackClicksProps, useTrackClicks } from 'src/components/SupernovaMainMenu/useTrackClicks';

import styles from './styles.less';

interface SupernovaActionLinkProps extends TrackClicksProps {
    active?: boolean;
    activeIcon?: React.ReactNode;
    onClick?: React.MouseEventHandler;
    name: string;
    url?: string;
    icon?: React.ReactNode;
    iconRight?: React.ReactNode;
    children?: React.ReactNode;
    badgeCount?: number;
    showBadge?: boolean;
    onlyIconOnMobile?: boolean;
}

const SupernovaActionLink: ForwardRefRenderFunction<HTMLButtonElement, SupernovaActionLinkProps> = (
    {
        active,
        icon,
        children,
        name,
        onClick,
        url,
        trackClicks,
        analytics,
        badgeCount,
        showBadge,
        activeIcon,
        iconRight,
        onlyIconOnMobile,
        ...props
    },
    forwardedRef
) => {
    const isMenuLightBg = useContext(isMenuLight);
    const isRedesignActive = useContext(isRedesignActiveContext);
    const isRedesignAnonymousActive = useContext(isRedesignAnonymouContext);

    const handleTrackClick = useTrackClicks(name, trackClicks, analytics);

    const typography = active && isRedesignActive ? 'subtitle-1-semibold' : 'label-2-regular';

    const handleClick = (event: React.MouseEvent) => {
        onClick && onClick(event);
        handleTrackClick();
    };

    let content = (
        <div className={styles.contentContainer}>
            <div
                className={classnames(styles.textContainer, {
                    [styles.hideTextOnMobile]: onlyIconOnMobile,
                    [styles.textContainerLight]: isMenuLightBg,
                    [styles.textContainerShaded]: !isMenuLightBg,
                    [styles.textContainerActive]: active,
                    [styles.textContainerRedesign]: isRedesignActive && !isRedesignAnonymousActive,
                })}
                {...props}
            >
                {/* Пока не включаем подсветку активности */}
                {/* {active ? activeIcon || icon : icon}*/}
                {icon}
                {children ? (
                    <div className={styles.text}>
                        <Text typography={typography}>{children}</Text>
                    </div>
                ) : null}
                {iconRight}
            </div>
        </div>
    );

    if (showBadge) {
        const badge = (
            <SupernovaBadge onLightBg={isMenuLightBg} data-qa={`${name}-badge`}>
                {badgeCount || ''}
            </SupernovaBadge>
        );
        content = children ? (
            <div className={styles.badgeContainer}>
                {content}
                {badge}
            </div>
        ) : (
            <ComponentWithBadge badge={badge}>{content}</ComponentWithBadge>
        );
    }

    const linkContent = <div className={styles.linkContent}>{content}</div>;

    return url ? (
        <Link onClick={handleClick} Element={SPALink} to={url} ref={forwardedRef} className={styles.container}>
            {linkContent}
        </Link>
    ) : (
        <Link ref={forwardedRef} onClick={handleClick} Element="button" className={styles.container}>
            {linkContent}
        </Link>
    );
};

export default forwardRef(SupernovaActionLink);
