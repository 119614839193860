import React from 'react';
import { AxiosError } from 'axios';

import { usePush } from '@hh.ru/redux-spa-middleware';

import { useNotification } from 'src/components/Notifications/Provider';
import {
    employerAccountSwitchError,
    employerAccountSwitchPassInvalidatedError,
} from 'src/components/SupernovaMainMenu/EmployerAccountSwitcherNotification';
import { useSelectorNonNullable } from 'src/hooks/useSelector';
import { fetcher } from 'src/utils/fetcher';

declare global {
    interface FetcherPutApi {
        '/account/switch': {
            body: void;
            response: void;
            queryParams: void;
        };
    }
}

const PASSWORD_UNVALIDATED = 'password-invalidated';

const useHandleSwitchAccountClick = (): (() => void) => {
    const loginUrl = useSelectorNonNullable(({ authUrl }) => authUrl['login-url']);
    const { addNotification } = useNotification();
    const push = usePush();

    return React.useCallback(() => {
        fetcher.put('/account/switch')?.then(
            () => {
                push('/');
            },
            (error: AxiosError) => {
                const statusCode = error?.response?.status;
                const errorCode = (error?.response?.data as Record<string, string> | undefined)?.errorCode;

                if (errorCode === PASSWORD_UNVALIDATED) {
                    addNotification(employerAccountSwitchPassInvalidatedError);
                    return;
                }

                if (statusCode === 403 && loginUrl) {
                    push(loginUrl);
                }

                addNotification(employerAccountSwitchError);
            }
        );
    }, [push, loginUrl, addNotification]);
};

export { useHandleSwitchAccountClick };
