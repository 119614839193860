import { useEffect } from 'react';

import headerGeoDefinitionElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/header/geo_definition/header_geo_definition_element_shown';
import { useBreakpoint } from '@hh.ru/magritte-ui';
import Button, { ButtonAppearance, ButtonKind, ButtonScale } from 'bloko/blocks/button';
import { FormSpacer } from 'bloko/blocks/form';
import NotificationFooter from 'bloko/blocks/notificationManager/NotificationFooter';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import sendHHAnalytics from 'HH/SendHHAnalytics';
import ElementShownAnchor from 'src/components/ElementShownAnchor';
import translation from 'src/components/translation';
import { useIsRedesignGeoExpActive } from 'src/hooks/useIsRedesignGeoExpActive';

import styles from './supernova-region-clarification.less';

const TrlKeys = {
    text: 'navi.regionClarification.text',
    confirmButton: 'navi.regionClarification.confirm',
    changeButton: 'navi.regionClarification.change',
};

export interface RegionClarificationProps {
    removeNotification: () => void;
    onChangeClick: () => void;
    onConfirmClick: () => void;
    translations: { area: string };
}

const RegionClarification: TranslatedComponent<RegionClarificationProps> = ({
    trls,
    removeNotification,
    onChangeClick,
    onConfirmClick,
    translations,
}) => {
    const { isGtS } = useBreakpoint();
    const isRedesignGeoExp = useIsRedesignGeoExpActive();

    useEffect(() => {
        if (isGtS && isRedesignGeoExp) {
            removeNotification();
        }
    }, [isGtS, isRedesignGeoExp, removeNotification]);

    return (
        <ElementShownAnchor fn={headerGeoDefinitionElementShown}>
            <div data-qa="region-clarification">
                {format(trls[TrlKeys.text], {
                    '{0}': translations.area,
                })}
                <NotificationFooter>
                    <div className={styles.wrapper}>
                        <Button
                            scale={ButtonScale.Small}
                            kind={ButtonKind.Primary}
                            data-qa="region-clarification-confirm"
                            onClick={() => {
                                removeNotification();
                                onConfirmClick();
                            }}
                        >
                            {trls[TrlKeys.confirmButton]}
                        </Button>
                        <FormSpacer />
                        <Button
                            scale={ButtonScale.Small}
                            kind={ButtonKind.Primary}
                            appearance={ButtonAppearance.Outlined}
                            data-qa="region-clarification-clarify"
                            onClick={() => {
                                removeNotification();
                                onChangeClick();
                            }}
                        >
                            {trls[TrlKeys.changeButton]}
                        </Button>
                    </div>
                </NotificationFooter>
            </div>
        </ElementShownAnchor>
    );
};

export default {
    Element: translation(RegionClarification),
    onClose: (): void => {
        void sendHHAnalytics({ goal: 'region-clarification-close' });
    },
};
