import { StaticDataFetcherItem } from '@hh.ru/magritte-ui';
import {
    ChatWritePossibility,
    DriverLicenseType,
    EmploymentForm,
    FlyInFlyOutDuration,
    SalaryRange,
    WorkScheduleByDays,
    WorkingHours,
    WorkFormat,
} from '@hh.ru/types-hh-microcore';

import { VacanciesFilterTypes } from 'src/components/EmployerVacancies/filterReducer';
import { FunnelInfo } from 'src/components/VacancyCreate/sections/PublicationType/FunnelSelector/fetchFunnelsList';
import { CurrentVacancyAuctionData } from 'src/models/auctionData';
import { MarketRange } from 'src/models/canvacVacancySalaryData';
import { CurrentVacancyClickmeAutoCampaignData } from 'src/models/clickme/clickmeAutoCampaignPromotion';
import { CurrencyType } from 'src/models/currencies.types';
import { ProductType } from 'src/models/price/product.types';
import { VacancyCompensation } from 'src/models/vacancy/compensation.type';
import { LanguageLevelList } from 'src/models/vacancy/languageLevel.types';
import {
    MetallicVacancyType as Metallic,
    MetallicVacancyTypeLower,
} from 'src/models/vacancy/metallicVacancyType.types';
/* tempexp_25221_next_line */
import { VacancyAdviceWithStatistics } from 'src/models/vacancy/vacancyAdvices.types';
import { VacancyFunnelLabel } from 'src/models/vacancyFunnel';
import { VacancyProperties } from 'src/models/vacancyProperties';
import NullableBoolean from 'src/types/nullableBoolean';

export const DEFAULT_GROUP_ID = 'all_vacancies';

export interface EmployerManager {
    hhid?: number;
    userId?: number;
    managerId: number;
    firstName: string | null;
    middleName: string | null;
    lastName: string | null;
}

export interface VacancyAdviceInfo {
    adviceWithStatistics: VacancyAdviceWithStatistics[];
    marketRange: MarketRange;
    salaryRange: SalaryRange;
}

export interface Vacancy {
    name: string;
    metallic: MetallicVacancyTypeLower;
    publicationType: Metallic;
    languages: LanguageLevelList;
    acceptIncompleteResumes: boolean;
    acceptTemporary: boolean;
    approved: boolean;
    archivationTime: string | null;
    archived: NullableBoolean;
    area: {
        id: number;
        name: string;
        path: string;
    };
    areaName: string;
    autoRenewal: {
        canBeEnabled: boolean;
        nextUpdate: string | null;
    };
    calls: {
        newMissed: number;
        total: number;
    };
    canChangeClosureStatus: boolean;
    canBeArchived: boolean;
    canBeProlongated: boolean;
    canBeShared: boolean;
    canEnableAutoProlongation: boolean;
    canShowMetallicUpgrade: boolean;
    changeTime: null | string;
    chatWritePossibility: ChatWritePossibility;
    code: null | string;
    comment: null | string;
    company: {
        badges: null | string;
        category: string;
        companySiteUrl: string;
        countryId: number;
        department: null | string;
        employerOrganizationFormId: number;
        id: number;
        logos: null | string;
        mainEmployerId: null | number;
        name: string;
        showOrganizationForm: boolean;
        showSimilarVacancies: boolean;
        state: string;
        trusted: boolean;
        visibleName: string;
    };
    companyUrl: null | string;
    compensation: VacancyCompensation;
    contactInfo: {
        callTrackingEnabled: NullableBoolean;
        email: string;
        fio: string;
        phones: {
            phones: {
                blank: boolean;
                city: number;
                comment: string;
                country: string;
                number: string;
            }[];
        };
    };
    clickmeAutoCampaignData: CurrentVacancyClickmeAutoCampaignData;
    creationSite: string;
    creationSiteId: number;
    creationTime: number;
    daysBeingPublic: null | string;
    daysCountFromPublicationTime: string;
    disabled: boolean;
    displayHost: string;
    driverLicenseTypes: DriverLicenseType[];
    employmentForm: EmploymentForm;
    employerManager: EmployerManager;
    expireTime: number;
    flyInFlyOutDuration: FlyInFlyOutDuration;
    forWorkingStaff: boolean;
    freeRestoreExpireTime: null;
    group: string;
    hasHiringPlan?: boolean;
    hrBrandInfo: null;
    inboxPossibility: boolean;
    isProlongateHighlight: string;
    lastChangeTime: {
        $: string;
        '@timestamp': number;
    };
    links: {
        desktop: string;
        mobile: string;
    };
    lowActivityVacancy: boolean;
    negotiations: {
        invitationsCount: number;
        notProcessedResponsesCount: number;
        responsesCount: number;
        resumesInProgressCount: number;
        topicsCount: number;
        unreadDiscardAfterInterviewsCount: number;
        unreadInvitationsCount: number;
    };
    notify: boolean;
    ownerEmployerManagerHhid: number;
    ownerEmployerManagerId: number;
    publicationPartnerType: number;
    publicationTime: number;
    responseLetterRequired: boolean;
    showContact: boolean;
    suggestProlongate: boolean;
    template: null;
    userTestId?: number;
    vacancyId: number;
    vacancyPlatforms: string[];
    vacancyPremoderateStatus: string;
    vacancyProperties: VacancyProperties;
    viewCount: number;
    auctionData?: CurrentVacancyAuctionData;
    professionalRoleIds: number[];
    vacancyAdvice: VacancyAdviceInfo;
    hasPermissionToPromote?: boolean;
    type: string;
    autoProlongationState?: Partial<{
        isPublicationRequired: boolean;
        propertiesTypeTrl: string;
        serviceCodes: string[];
        purchase?: {
            products: ProductType[];
            purchaseParams: Record<string, string>;
            currency: CurrencyType;
            totalPrice: string;
        };
    }>;
    brandSnippetId: string | null;
    brandSnippetExpirationTime: {
        $: string;
        '@timestamp': string;
    } | null;
    nightShifts: boolean;
    internship: boolean;
    workScheduleByDays: WorkScheduleByDays;
    workingHours: WorkingHours;
    workFormat: WorkFormat;
    // tempexp_32165_next_line
    discountInPercent?: number;
    funnel?: FunnelInfo;
}

export interface TopicAutoActionRules {
    ruleExistenceByVacancyId: {
        [vacancyId: number]: {
            vacancyId: number;
            ruleExists: boolean;
        };
    };
}

export type ManagerId = number;

export interface VacanciesList {
    activeVacanciesCount: number;
    isAnyVacancyHasBrandingTemplate: boolean;
    isAuctionPresent: boolean;
    isCallsPresent: boolean;
    vacancyOrder: string;
    permissions: Record<string, Record<ManagerId, boolean>>;
    list: Vacancy[];
    topicAutoActionRules?: TopicAutoActionRules;
}

export interface VacanciesGroupItem {
    ownerEmployerManagerId?: number;
    name: string;
    counters: {
        archivedVacancyCount: number;
        notProcessedResponsesCount: number;
        responsesCount: number;
        totalVacancyCount: number;
        vacancyProlongateHighlightCount: number;
        topicsInProgressCount: number;
    };
    groupId: string;
    groupLabels: VacancyFunnelLabel[];
    vacanciesList: Vacancy[];
    default: boolean;
    funnelHasTasksInProgress?: boolean;
}

export interface VacanciesGroups {
    activeVacanciesCount: number;
    isAnyVacancyHasBrandingTemplate: boolean;
    isAuctionPresent: boolean;
    isCallsPresent: boolean;
    vacancyOrder: string;
    permissions: Record<string, Record<ManagerId, boolean>>;
    vacanciesGroupList: VacanciesGroupItem[];
    topicAutoActionRules?: TopicAutoActionRules;
    totalVacanciesGroupCount?: number;
}

export type PublicationsList = {
    publicationType: string;
    count: number;
    vacancyProperties: VacancyProperties;
}[];

interface ShortVacancy {
    id: number;
    areas: { name: string; id: number; path: string }[];
    completedFieldsPercentage: number;
    employerServiceNotSpent: boolean;
    isoLastChangeTime: string;
    name: string;
    publicationReady: boolean;
    publicationType: null;
    validationErrors: null;
    insufficientQuotas: PublicationsList;
    insufficientPublications: PublicationsList;
    requiredPublications: PublicationsList;
    vacancyProperties: VacancyProperties;
    hasAdditionalProductsToPay: boolean;
}

export type Drafts = ShortVacancy[];

export interface BillInfo {
    billUid: string;
    cartId: number;
    draftId: string | null;
    isAssistLinkAvailable: boolean | null;
}

export interface ManagerVacancies {
    draftsTotalCount?: number;
    drafts?: Drafts;
    vacanciesGroups?: VacanciesGroups;
    autoPublications?: {
        list: ShortVacancy[];
        billsByDrafts: BillInfo;
    };
    scheduledPublications?: {
        list: ShortVacancy[];
        publicationTime: {
            draftId: string | null;
            isoDateTimePublication: string;
            gmt: string;
        };
    };
    isFetching?: boolean;
    error?: boolean;
}

export const DEFAULT_FILTERS_VALUES = {
    areaId: [],
    employerManagerId: [],
    vacancyDefiningProperty: [],
    vacancyPfpProperty: [],
    vacancyPublicationFormat: [],
    vacancyVisibility: [],
    premoderateStatus: [],
    vacancyName: '',
    auctionState: [],
    clickmeMultiPostingState: [],
};

export const ALL_VACANCIES_GROUP_ID = 'all';
export const COMBINED_VACANCIES_FILTERED_GROUP_ID = 'combined';
export const VACANCIES_GROUPS_HEIGHT = 42;

export const enum VacanciesFilterKeys {
    AreaId = 'areaId',
    EmployerManagerId = 'employerManagerId',
    VacancyDefiningProperty = 'vacancyDefiningProperty',
    VacancyPfpProperty = 'vacancyPfpProperty',
    VacancyPublicationFormat = 'vacancyPublicationFormat',
    VacancyVisibility = 'vacancyVisibility',
    PremoderateStatus = 'premoderateStatus',
    AuctionState = 'auctionState',
    ClickmeMultiPostingState = 'clickmeMultiPostingState',
}

export interface VacanciesFilters {
    [VacanciesFilterKeys.AreaId]: string[];
    [VacanciesFilterKeys.EmployerManagerId]: string[];
    [VacanciesFilterKeys.VacancyDefiningProperty]: string[];
    [VacanciesFilterKeys.VacancyPfpProperty]: string[];
    [VacanciesFilterKeys.VacancyPublicationFormat]: string[];
    [VacanciesFilterKeys.VacancyVisibility]: string[];
    [VacanciesFilterKeys.PremoderateStatus]: string[];
    [VacanciesFilterKeys.AuctionState]: string[];
    [VacanciesFilterKeys.ClickmeMultiPostingState]: string[];
    vacancyName: string;
}

export interface AvailableVacanciesFilters {
    [VacanciesFilterKeys.AreaId]: Set<string> | null;
    [VacanciesFilterKeys.EmployerManagerId]: Set<string> | null;
    [VacanciesFilterKeys.VacancyDefiningProperty]: Set<string> | null;
    [VacanciesFilterKeys.VacancyPfpProperty]: Set<string> | null;
    [VacanciesFilterKeys.VacancyPublicationFormat]: Set<string> | null;
    [VacanciesFilterKeys.VacancyVisibility]: Set<string> | null;
    [VacanciesFilterKeys.PremoderateStatus]: Set<string> | null;
    [VacanciesFilterKeys.AuctionState]: Set<string> | null;
    [VacanciesFilterKeys.ClickmeMultiPostingState]: Set<string> | null;
}

export type VacancyFilterItem = StaticDataFetcherItem & { data: VacanciesFilterKeys };

export type VacanciesFilterItems = {
    [key in VacanciesFilterKeys]: VacancyFilterItem[];
};

export type SimpleFilterItemType = Partial<Record<'id' | 'value', string>> & { text: string };

export interface ExtendedSimpleFilterItemType {
    id: string;
    text: string;
    checked: boolean;
    filterType: VacanciesFilterTypes;
}

export enum VacancyPromotionType {
    Auction = 'Auction',
    Branding = 'Branding',
    BrandSnippet = 'BrandSnippet',
    ClickmeAutoCampaign = 'ClickmeAutoCampaign',
}

export type LowActivityVacancy = Pick<Vacancy, 'name' | 'vacancyId' | 'areaName' | 'negotiations'> & {
    employerManager: Vacancy['employerManager'] | null;
    typeTrl: string;
};
