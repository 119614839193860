import { useCallback, useEffect, useState } from 'react';

import Cookies from 'bloko/common/Cookies';

import { COOKIE_AGREEMENT_NAME, RESELECT_PARAMETER } from 'src/components/CookiesPolicy/utils';
import { useIsBelarus, useIsRussia } from 'src/hooks/useCountries';
import { useFeatureEnabled } from 'src/hooks/useFeatureEnabled';
import { useSelector } from 'src/hooks/useSelector';

export const useIsShowTopBanner = (): boolean => {
    const [isShow, setShowState] = useState(false);
    const enableCookiePolicyInformer = useFeatureEnabled('enable_cookie_policy_informer');
    const isCookiesPolicyBannerEnabled = useFeatureEnabled('cookies_policy_banner');
    const isRussia = useIsRussia();
    const isBelarus = useIsBelarus();
    const reselectCookiePolicy = useSelector((state) => state.router.location.query?.[RESELECT_PARAMETER] === 'true');
    const isCookiesPolicyInformerVisible = useSelector((state) => state.isCookiesPolicyInformerVisible);
    const isShowTopPromoBanner = useSelector(({ topPromoBanner }) => topPromoBanner.isShow);

    const isCookiesPolicyInformerShown = useCallback(() => {
        if (!isRussia || !enableCookiePolicyInformer) {
            return false;
        }

        const cookieAgreementValue = Cookies.get(COOKIE_AGREEMENT_NAME);

        return cookieAgreementValue === null;
    }, [enableCookiePolicyInformer, isRussia]);

    const isCookiesBannerShown = useCallback(() => {
        if (isCookiesPolicyInformerVisible) {
            return true;
        }

        if (!(isCookiesPolicyBannerEnabled && isBelarus)) {
            return false;
        }

        const cookieAgreementValue = Cookies.get(COOKIE_AGREEMENT_NAME);
        const isAlreadyAnswered = cookieAgreementValue !== null;

        return !isAlreadyAnswered || reselectCookiePolicy;
    }, [isBelarus, isCookiesPolicyBannerEnabled, isCookiesPolicyInformerVisible, reselectCookiePolicy]);

    useEffect(() => {
        if (isShowTopPromoBanner && !(isCookiesBannerShown() || isCookiesPolicyInformerShown())) {
            setShowState(true);
        } else {
            setShowState(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShowTopPromoBanner]);

    return isShow;
};
