import { useEffect, useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';
import talantixLinkContactsHideButtonClick from '@hh.ru/analytics-js-events/build/talantix/employer/talantix_link/talantix_link_contacts_hide_button_click';
import talantixLinkContactsHideElementShown from '@hh.ru/analytics-js-events/build/talantix/employer/talantix_link/talantix_link_contacts_hide_element_shown';
import { Link } from '@hh.ru/magritte-ui';
import ConversionNumber from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useEmployerBillingInfo } from 'src/components/SupernovaMainMenu/EmployerProfile/hooks/useEmployerBillingInfo';
import translation from 'src/components/translation';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import Informer from 'src/components/SupernovaMainMenu/EmployerProfile/Informers/Informer';

const TrlKeys = {
    talantixDaysLeftStart: 'employer.employerProfile.popup.informers.talantixDaysLeftStart',
    talantixDaysLeftLinkText: 'employer.employerProfile.popup.informers.talantixDaysLeftLinkText',
    talantixTariffDaysLeftZero: 'supernova.billingInfo.talantixTariffDaysLeft.zero',
    days: {
        one: 'index.employer.billing.days.one',
        some: 'index.employer.billing.days.some',
        many: 'index.employer.billing.days.many',
    },
};

const talantixUrl = 'https://talantix.ru/ats/vacancies?utm_source=hh.ru&utm_medium=referral&utm_campaign=tariff_days';

const onTalantixTariffDaysLeftLinkClick = () => {
    Analytics.sendEvent('talantix', 'tariffDaysLeftLinkClick', undefined, undefined, true);
    talantixLinkContactsHideButtonClick({ hhtmSourceLabel: 'menu_item' });
};

const TalantixDaysLeftInformer: TranslatedComponent = ({ trls }) => {
    const { billingInfo } = useEmployerBillingInfo();
    const talantixLinkRef = useRef<HTMLAnchorElement>(null);

    useEffect(() => {
        if (talantixLinkRef.current) {
            Analytics.sendEventElementShown(talantixLinkRef.current, 'talantix', 'tariffDaysLeftLinkShow');
            talantixLinkContactsHideElementShown(talantixLinkRef.current, { hhtmSourceLabel: 'menu_item' });
        }
    }, [billingInfo.talantixTariffDaysLeft]);

    if (!billingInfo.talantixTariffDaysLeft && billingInfo.talantixTariffDaysLeft !== 0) {
        return null;
    }

    return (
        <Informer
            data-qa="talantix_days"
            title={
                <>
                    {trls[TrlKeys.talantixDaysLeftStart]}
                    {NON_BREAKING_SPACE}
                    <Link
                        inline
                        ref={talantixLinkRef}
                        onClick={onTalantixTariffDaysLeftLinkClick}
                        href={talantixUrl}
                        target="_blank"
                        typography="label-3-regular"
                    >
                        {trls[TrlKeys.talantixDaysLeftLinkText]}
                    </Link>
                </>
            }
        >
            {billingInfo.talantixTariffDaysLeft === 0 && trls[TrlKeys.talantixTariffDaysLeftZero]}
            {billingInfo.talantixTariffDaysLeft > 0 && (
                <ConversionNumber
                    hasValue
                    one={trls[TrlKeys.days.one]}
                    some={trls[TrlKeys.days.many]}
                    many={trls[TrlKeys.days.some]}
                    value={billingInfo.talantixTariffDaysLeft}
                />
            )}
        </Informer>
    );
};

export default translation(TalantixDaysLeftInformer);
