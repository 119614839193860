import floatingButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/chat/chat_floating_button_click';
import { useChatik } from '@hh.ru/chatik-integration';
import { TranslatedComponent } from '@hh.ru/front-static-app';
import { Badge, ComponentWithBadge } from '@hh.ru/magritte-ui-badge';
import { BubbleRoundFilledSize24 } from '@hh.ru/magritte-ui/icon';

import FloatingButton from 'src/components/FloatingButton';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import { useFloatChatikActivatorVisible } from 'src/components/FloatChatikActivator/useFloatChatikActivatorVisible';

import styles from './float-chatik-activator.less';

/*
 * Можем указать список страниц, для которых должен быть задан дефолтный отступ снизу в 80px
 * Сделано на случай, если в рамках эксперимента будут страницы, на которых будет перекрыт важный стики-контент снизу
 */
const DEFAULT_SHIFT = 80;

const TrlKeys = {
    text: 'floatChatikActivator.text',
};

const FloatChatikActivator: TranslatedComponent = ({ trls }) => {
    const { openChatik } = useChatik();

    const calculatedShift = useSelector((state) => state.chatikFloatActivator.shift || 0);
    const shouldUsingCalculatedShift = useSelector((state) => !state.chatikFloatActivator.fixed);
    const unreadCount = useSelector(({ chatikCounters }) => chatikCounters.unreadCount || 0);

    const visible = useFloatChatikActivatorVisible();

    const handleChatikOpen = () => {
        floatingButtonClick({ unreadChatCount: unreadCount });
        openChatik({ hhtmFromLabel: 'chat_floating', view: 'medium' });
    };

    return visible ? (
        <div
            className={styles.floatChatikActivator}
            // @ts-expect-error custom css property
            style={{ '--float-chatik-shift': `${shouldUsingCalculatedShift ? calculatedShift : DEFAULT_SHIFT}px` }}
        >
            <ComponentWithBadge
                offset={4}
                badge={
                    unreadCount > 0 ? (
                        <Badge size="small" style="attention">
                            {unreadCount > 99 ? '99+' : unreadCount.toString()}
                        </Badge>
                    ) : undefined
                }
            >
                <FloatingButton onClick={handleChatikOpen}>
                    <BubbleRoundFilledSize24 initialColor="contrast" />
                    <span className={styles.title}>{trls[TrlKeys.text]}</span>
                </FloatingButton>
            </ComponentWithBadge>
        </div>
    ) : null;
};

export default translation(FloatChatikActivator);
