import React, { useContext } from 'react';

import { Breakpoint } from '@hh.ru/magritte-ui';

import { NavItem } from 'src/models/supernovaNaviMenu';

import SupernovaActionLink from 'src/components/SupernovaMainMenu/SupernovaActionLink';
import SupernovaModals from 'src/components/SupernovaMainMenu/SupernovaModals';
import SupernovaNaviItem from 'src/components/SupernovaMainMenu/SupernovaNaviItem';
import isRedesignActiveContext from 'src/components/SupernovaMainMenu/isRedesignActiveContext';
import { useGoogleAnalytics } from 'src/components/SupernovaMainMenu/useGoogleAnalytics';
import { useTrackClicks } from 'src/components/SupernovaMainMenu/useTrackClicks';

const VISIBLE_ON = [Breakpoint.L];

const Support: React.FC<NavItem> = (props) => {
    const isRedesignActive = useContext(isRedesignActiveContext);

    const modalActivatorRef = React.useRef(null);

    const handleGoogleAnalyticsClick = useGoogleAnalytics('help');
    const handleTrackClick = useTrackClicks(props.name, props.trackClicks, props.analytics);

    const handleClick = () => {
        handleGoogleAnalyticsClick();
        handleTrackClick();
    };

    return (
        <SupernovaNaviItem visibleOn={VISIBLE_ON}>
            {isRedesignActive ? (
                <SupernovaActionLink
                    ref={modalActivatorRef}
                    data-qa={`mainmenu_${props.name}`}
                    name={props.name}
                    onClick={handleClick}
                >
                    {props.translations.name}
                </SupernovaActionLink>
            ) : (
                <button
                    className="supernova-link"
                    data-qa={`mainmenu_${props.name}`}
                    type="button"
                    onClick={handleClick}
                    ref={modalActivatorRef}
                >
                    {props.translations.name}
                </button>
            )}
            <SupernovaModals modalType={props.modalType} activatorRef={modalActivatorRef} subItems={props.subItems} />
        </SupernovaNaviItem>
    );
};

export default Support;
