import { useChatik } from '@hh.ru/chatik-integration';
import { useBreakpoint } from '@hh.ru/magritte-ui';

import { useIsClient } from 'src/hooks/useIsClient';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

const useFloatChatikActivatorVisible = () => {
    const { isMobile } = useBreakpoint();
    const { isChatikOpen } = useChatik();
    const isClient = useIsClient();
    const userType = useSelector((state) => state.userType);
    const disabled = useSelector((state) => state.chatikFloatActivator.disabled);

    const isActivatorAvailable = !isMobile && !disabled;

    return (
        isActivatorAvailable &&
        !isChatikOpen &&
        isClient &&
        (userType === UserType.Employer || userType === UserType.Applicant)
    );
};

export { useFloatChatikActivatorVisible };
