import { FC } from 'react';

import { HSpacingContainer } from '@hh.ru/magritte-ui';

import { MessengerType } from 'src/models/messengers';

import SupportMessenger from 'src/components/Support/SupportMessengers/SupportMessenger';
import { useSupportMessengers } from 'src/components/Support/SupportMessengers/useSupportMessengers';

interface SupportMessengersProps {
    onMessengerClick?: (messenger: MessengerType) => void;
}

const SupportMessengers: FC<SupportMessengersProps> = ({ onMessengerClick }) => {
    const messengers = useSupportMessengers();
    return (
        <HSpacingContainer default={12}>
            {messengers.map(({ href, messenger }) => {
                return (
                    <SupportMessenger
                        key={messenger}
                        onClick={() => onMessengerClick?.(messenger)}
                        messenger={messenger}
                        href={href}
                    />
                );
            })}
        </HSpacingContainer>
    );
};

export default SupportMessengers;
