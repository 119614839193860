import { Dispatch, FC, SetStateAction, useRef, MouseEvent } from 'react';

import { Badge, Card, HSpacing, HSpacingContainer, VSpacing, VSpacingContainer, Breakpoint } from '@hh.ru/magritte-ui';
import { BarsOutlinedSize24, PlusOutlinedSize16 } from '@hh.ru/magritte-ui/icon';

import MobileScreen from 'src/components/SupernovaMainMenu/MobileScreen';
import SupernovaActionLink from 'src/components/SupernovaMainMenu/SupernovaActionLink';
import SupernovaButton from 'src/components/SupernovaMainMenu/SupernovaButton';
import SupernovaIcon, { SupernovaIconView } from 'src/components/SupernovaMainMenu/SupernovaIcon';
import SupernovaModals from 'src/components/SupernovaMainMenu/SupernovaModals';
import SupernovaNaviItem from 'src/components/SupernovaMainMenu/SupernovaNaviItem';
import { useIsAuthorizedRedesign } from 'src/components/SupernovaMainMenu/hooks/useIsAuthorizedRedesign';
import { useExperiment } from 'src/hooks/useExperiment';
import { useOnOffState } from 'src/hooks/useOnOffState';
import { useSelector } from 'src/hooks/useSelector';
import { NavItem } from 'src/models/supernovaNaviMenu';

import MaxResumesLimitSnackbar from 'src/components/SupernovaMainMenu/MobileMagritte/MaxResumesLimitSnackbar';
import MobileMagritteHeader from 'src/components/SupernovaMainMenu/MobileMagritte/MobileMagritteHeader';
import MobileMagritteLink from 'src/components/SupernovaMainMenu/MobileMagritte/MobileMagritteLink';

import styles from './styles.less';

interface MobileMagritteProps extends Pick<NavItem, 'name' | 'analytics' | 'trackClicks' | 'subItems'> {
    shouldShowCounter: boolean;
    currentScreen: string;
    setExpanded: Dispatch<SetStateAction<boolean>>;
    setCurrentScreen: Dispatch<SetStateAction<string>>;
    isExpanded: boolean;
    onAreaSwitchClick: () => void;
    onClose: () => void;
}

const VISIBLE_ON = [Breakpoint.XS];

const MobileMagritte: FC<MobileMagritteProps> = ({
    shouldShowCounter,
    trackClicks,
    name,
    subItems,
    analytics,
    isExpanded,
    setExpanded,
    currentScreen,
    onAreaSwitchClick,
    setCurrentScreen,
    onClose,
}) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const isRedesignAnonymousMenuExpB = useExperiment('redesign_anonymous_menu_v2_b', true, false);
    const isAuthorizedRedesign = useIsAuthorizedRedesign();
    const [showResumesLimitWarning, setOnShowResumesLimitWarning, setOffShowResumesLimitWarning] = useOnOffState(false);
    const remaining = useSelector(({ resumeLimits }) => resumeLimits?.remaining);

    const helpActivatorRef = useRef(null);

    const handleToggleExpandedClick = () => {
        setExpanded((prev) => !prev);
    };

    const handleOverlayClick = (event: MouseEvent) => {
        if (event.target instanceof Element && contentRef.current) {
            if (!contentRef.current.contains(event.target) && event.target !== contentRef.current) {
                onClose();
            }
        }
    };

    const handleChangeCurrentScreen = (name: NavItem['name']) => {
        setCurrentScreen(name);
    };

    const handleButtonClick = (name: NavItem['name'], event: MouseEvent) => {
        if (name === 'createResume' && remaining === 0) {
            event.preventDefault();
            setOnShowResumesLimitWarning();
            onClose();
        }
    };

    const isDefaultScreen = currentScreen === 'default';

    const areaSwitcher = subItems?.find((item) => item.name === 'areaSwitcher');

    const mainContent = subItems?.find((item) => item.name === 'mainContent');

    const linksItems = mainContent?.subItems?.find((item) => item.name === 'links');
    const buttonsItems = mainContent?.subItems?.find((item) => item.name === 'buttons');
    const help = linksItems?.subItems?.find((item) => ['help', 'anonSupport', 'support'].includes(item.name));

    return (
        <>
            <SupernovaNaviItem visibleOn={VISIBLE_ON}>
                {isAuthorizedRedesign ? (
                    <SupernovaActionLink
                        data-qa={`mainmenu_${name}`}
                        name={name}
                        analytics={analytics}
                        trackClicks={trackClicks}
                        icon={<BarsOutlinedSize24 />}
                        onClick={handleToggleExpandedClick}
                        showBadge={shouldShowCounter}
                    />
                ) : (
                    <SupernovaIcon
                        isMagritte
                        magritteOnlyIcon
                        magritteButtonMode={isRedesignAnonymousMenuExpB ? 'secondary' : 'tertiary'}
                        magritteBadge={shouldShowCounter ? <Badge size="extra-small" /> : undefined}
                        data-qa={`mainmenu_${name}`}
                        name={name}
                        analytics={analytics}
                        trackClicks={trackClicks}
                        view={isRedesignAnonymousMenuExpB ? SupernovaIconView.MobileSmall : SupernovaIconView.Mobile}
                        onClick={handleToggleExpandedClick}
                    />
                )}
            </SupernovaNaviItem>
            {isExpanded && (
                <>
                    <div onClick={handleOverlayClick} className={styles.mobileScreenOverlay}>
                        <Card
                            ref={contentRef}
                            borderBottomRightRadius={24}
                            borderBottomLeftRadius={24}
                            stretched
                            style={isDefaultScreen ? 'constant' : 'primary'}
                        >
                            {isDefaultScreen ? (
                                <>
                                    <MobileMagritteHeader
                                        onCloseClick={onClose}
                                        onAreaSwitcherClick={onAreaSwitchClick}
                                        areaSwitcher={areaSwitcher}
                                        onLightBg={!isDefaultScreen}
                                    />
                                    <VSpacing default={24} />
                                    <VSpacingContainer default={12}>
                                        {linksItems?.subItems?.map((item) => {
                                            switch (item.name) {
                                                case 'help':
                                                case 'anonSupport':
                                                case 'support':
                                                    return (
                                                        <div key={item.name} ref={helpActivatorRef}>
                                                            <HSpacingContainer default={0}>
                                                                <HSpacing default={16} />
                                                                <MobileMagritteLink {...item} />
                                                                <HSpacing default={16} />
                                                            </HSpacingContainer>
                                                        </div>
                                                    );
                                                default:
                                                    return (
                                                        <HSpacingContainer key={item.name} default={0}>
                                                            <HSpacing default={16} />
                                                            <MobileMagritteLink
                                                                onClick={
                                                                    item.dropdown
                                                                        ? () => handleChangeCurrentScreen(item.name)
                                                                        : undefined
                                                                }
                                                                {...item}
                                                            />
                                                            <HSpacing default={16} />
                                                        </HSpacingContainer>
                                                    );
                                            }
                                        })}
                                    </VSpacingContainer>
                                    <VSpacing default={24} />
                                    {buttonsItems?.subItems?.length ? (
                                        <>
                                            <VSpacing default={12} />
                                            <VSpacingContainer default={12}>
                                                {buttonsItems.subItems.map((item) => {
                                                    return (
                                                        <div key={item.name}>
                                                            <HSpacingContainer default={0} key={item.name}>
                                                                <HSpacing default={12} />
                                                                <SupernovaButton
                                                                    name={item.name}
                                                                    trackClicks={item.trackClicks}
                                                                    analytics={item.analytics}
                                                                    isMagritte
                                                                    onClick={(event) =>
                                                                        handleButtonClick(item.name, event)
                                                                    }
                                                                    magritteMode="secondary"
                                                                    url={item.url}
                                                                    data-qa={`mobile-menu-${item.name}`}
                                                                    rel="nofollow"
                                                                    magritteSize="medium"
                                                                    magritteStyle="contrast"
                                                                    magritteStretched
                                                                >
                                                                    <div className={styles.buttonContent}>
                                                                        {item.translations.name}
                                                                        {item.name === 'vacancyCreate' ? (
                                                                            <PlusOutlinedSize16 initialColor="contrast" />
                                                                        ) : undefined}
                                                                    </div>
                                                                </SupernovaButton>
                                                                <HSpacing default={12} />
                                                            </HSpacingContainer>
                                                        </div>
                                                    );
                                                })}
                                            </VSpacingContainer>
                                            <VSpacing default={12} />
                                        </>
                                    ) : null}
                                </>
                            ) : (
                                subItems?.map((item) => {
                                    return (
                                        <MobileScreen
                                            key={item.name}
                                            currentScreen={currentScreen}
                                            setDefaultScreen={onClose}
                                            close={onClose}
                                            {...item}
                                        />
                                    );
                                })
                            )}
                        </Card>
                    </div>
                    <SupernovaModals
                        modalType={help?.modalType}
                        activatorRef={helpActivatorRef}
                        subItems={help?.subItems}
                        onClose={onClose}
                    />
                </>
            )}
            <MaxResumesLimitSnackbar visible={showResumesLimitWarning} onClose={setOffShowResumesLimitWarning} />
        </>
    );
};

export default MobileMagritte;
