import { KeyboardEventHandler, useState, useCallback, useMemo, useEffect, useRef, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { useBreakpoint, SearchInput } from '@hh.ru/magritte-ui';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { dispatchNotLuxDataUpdate } from 'Modules/notLuxData';
import AutoInviteContext from 'src/components/AutoInviteModal/common/AutoInviteContext';
import SuggestWithDefaultErrorPlaceholder from 'src/components/SuggestWithDefaultErrorPlaceholder';
import { useSendAnalytics } from 'src/components/SupernovaSearch/SmartSuggest/useSendAnalytics';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import {
    ComponentProps,
    SuggestItem,
} from 'src/components/ResumeAdvancedSearch/SearchQueries/MagritteSearchQuery/types';
import { useProvider } from 'src/components/ResumeAdvancedSearch/SearchQueries/MagritteSearchQuery/useProvider';

const TrlKeys = {
    searchQueryPlaceholder: 'supernova.search.placeholder.resumeSearch',
};

const MAX_QUERY_LENGTH = 400;
const updateLastSearchQueryText = makeSetStoreField('lastUpdatedSearchQueryText');

const MagritteSearchQuery: TranslatedComponent<ComponentProps> = ({ trls, query, setFromSuggest, onSubmit }) => {
    const dispatch = useDispatch();
    const dataProvider = useProvider();
    const { isMobile } = useBreakpoint();
    const inputRef = useRef<HTMLInputElement>(null);
    const { sendSuggestItemClickAnalytics, sendSuggestShownAnalytics } = useSendAnalytics();
    const autoInviteHandlerFormChange = useContext(AutoInviteContext);
    const criteriaTextUpdated = useSelector(({ criteriaTextUpdated }) => criteriaTextUpdated);
    const [text, setText] = useState(
        query.text[0] || (Array.isArray(criteriaTextUpdated) ? criteriaTextUpdated[0] : criteriaTextUpdated) || ''
    );

    useEffect(() => {
        autoInviteHandlerFormChange?.();
    }, [autoInviteHandlerFormChange, text]);

    const handleTextUpdate = useCallback(
        (text: string) => {
            setText(text);
            dispatch(updateLastSearchQueryText(text));
            dispatchNotLuxDataUpdate({ inputValue: text });
        },
        [setText, dispatch]
    );

    const handleItemSelect = useCallback(
        (_: string, item?: SuggestItem): boolean => {
            if (item) {
                sendSuggestItemClickAnalytics(item);
                handleTextUpdate(item.text);
                setFromSuggest(true);
            }

            return true;
        },
        [handleTextUpdate, sendSuggestItemClickAnalytics, setFromSuggest]
    );

    const onSubmitInput: KeyboardEventHandler<HTMLInputElement> = useCallback(
        (event) => {
            // Проверяем на isMobile, чтобы в десктопной версии не вызывать триггер формы дважды.
            // Там срабатывает нативный сабмит внутри формы.
            if (event.key === 'Enter' && isMobile) {
                onSubmit?.();
            }
        },
        [isMobile, onSubmit]
    );

    const inputTitle = trls[TrlKeys.searchQueryPlaceholder];
    const inputProps = useMemo(() => {
        return {
            ref: inputRef,
            name: 'text',
            value: text,
            onChange: handleTextUpdate,
            onKeyDown: onSubmitInput,
            clearable: true,
            maxLength: MAX_QUERY_LENGTH,
            autoComplete: 'off',
            placeholder: inputTitle,
            'aria-label': inputTitle,
            'data-qa': 'resumes-search-wizard-item-keyword',
        };
    }, [text, handleTextUpdate, onSubmitInput, inputTitle]);

    return (
        <SuggestWithDefaultErrorPlaceholder
            inputValue={text}
            onSelectValidator={handleItemSelect}
            dataProvider={dataProvider}
            input={{
                component: SearchInput,
                props: inputProps,
            }}
            navigationBarProps={{
                title: inputTitle,
            }}
            onDropOpen={sendSuggestShownAnalytics}
            onBottomSheetOpen={sendSuggestShownAnalytics}
        />
    );
};

export default translation(MagritteSearchQuery);
