import { useRef } from 'react';

import { Drop, Link, Button } from '@hh.ru/magritte-ui';
import { ChevronDownOutlinedSize16, ChevronUpOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import BlokoButton, { ButtonScale } from 'bloko/blocks/button';
import ButtonSelect from 'bloko/blocks/button/ButtonSelect';
import ClickDown, { DownLayer, DownPlacement } from 'bloko/blocks/drop/Down/Click';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useOnOffState } from 'src/hooks/useOnOffState';
import { PosOption, SearchQueries } from 'src/models/search/resume/queries';
import { CriteriaKey } from 'src/types/search/common/criteria';

import PosSelect from 'src/components/ResumeAdvancedSearch/SearchQueries/PosSelect';
import PosSelectTitle from 'src/components/ResumeAdvancedSearch/SearchQueries/PosSelectTitle';
import {
    getHasWorkplaceOptions,
    getStateWithDefaultValues,
} from 'src/components/ResumeAdvancedSearch/SearchQueries/posUtils';

const TrlKeys = {
    submit: 'resumesSearch.advanced.apply',
};

interface PosSelectorDropProps {
    query: SearchQueries;
    setQuery: (value: SearchQueries) => void;
    setHasWorkplaceOptions: (hasWorkplaceOptions: boolean) => void;
    isMagritte?: boolean;
}

const PosSelectorDrop: TranslatedComponent<PosSelectorDropProps> = ({
    trls,
    query,
    setQuery,
    setHasWorkplaceOptions,
    isMagritte,
}) => {
    const [isDropVisible, showDrop, hideDrop] = useOnOffState(false);
    const activatorRef = useRef(null);

    const handlePosChange = (values: PosOption[]) => {
        setHasWorkplaceOptions(getHasWorkplaceOptions(values));
        setQuery(getStateWithDefaultValues({ ...query, [CriteriaKey.Pos]: values }, values));
    };

    const selectorDataQa = `resumes-search-wizard-${CriteriaKey.Pos}-switcher`;
    const buttonCloseDataQa = 'resumes-search-wizard-pos-close';

    return isMagritte ? (
        <>
            <input type="hidden" name={CriteriaKey.Pos} value={query[CriteriaKey.Pos].join(',')} />
            <Drop
                visible={isDropVisible}
                placement="bottom-left"
                activatorRef={activatorRef}
                onClose={hideDrop}
                maxWidth={368}
                minHeight={400}
                ignoreIntersections
                forcePlacement
                footer={
                    <Button style="accent" mode="primary" onClick={hideDrop} data-qa={buttonCloseDataQa} stretched>
                        {trls[TrlKeys.submit]}
                    </Button>
                }
            >
                <PosSelect selectedValues={query[CriteriaKey.Pos]} setSelectedValues={handlePosChange} isMagritte />
            </Drop>
            <Link
                typography="label-3-regular"
                style="neutral"
                ref={activatorRef}
                onClick={showDrop}
                iconRight={isDropVisible ? <ChevronUpOutlinedSize16 /> : <ChevronDownOutlinedSize16 />}
                data-qa={selectorDataQa}
            >
                <PosSelectTitle values={query[CriteriaKey.Pos]} />
            </Link>
        </>
    ) : (
        <>
            <input type="hidden" name={CriteriaKey.Pos} value={query[CriteriaKey.Pos].join(',')} />
            <ClickDown
                render={(close) => (
                    <>
                        <PosSelect selectedValues={query[CriteriaKey.Pos]} setSelectedValues={handlePosChange} />
                        <BlokoButton scale={ButtonScale.Small} onClick={close} data-qa={buttonCloseDataQa}>
                            {trls[TrlKeys.submit]}
                        </BlokoButton>
                    </>
                )}
                placement={DownPlacement.BottomStart}
                layer={DownLayer.AboveOverlayContent}
                activatorRef={activatorRef}
            >
                <ButtonSelect data-qa={selectorDataQa} flexible light innerRef={activatorRef}>
                    <PosSelectTitle values={query[CriteriaKey.Pos]} />
                </ButtonSelect>
            </ClickDown>
        </>
    );
};

export default translation(PosSelectorDrop);
