import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

const useIsAuthorizedRedesign = (): boolean => {
    const userType = useSelector(({ userType }) => userType);

    return [UserType.Applicant, UserType.Employer].includes(userType);
};

export { useIsAuthorizedRedesign };
