import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import userStatusSelectOfferCompanyButtonClick, {
    JobSearchStatus as JobSearchStatusAnalytics,
} from '@hh.ru/analytics-js-events/build/xhh/applicant/user_status/user_status_select_offer_company_button_click';
import userStatusSelectOfferCompanyEmptyButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/user_status/user_status_select_offer_company_empty_button_click';
import userStatusSelectSendWhatConfused from '@hh.ru/analytics-js-events/build/xhh/applicant/user_status/user_status_select_send_what_confused_button_click';
import userStatusSelectWhereFoundButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/user_status/user_status_select_where_found_button_click';
import userStatusSelectWhereFoundScreenShown from '@hh.ru/analytics-js-events/build/xhh/applicant/user_status/user_status_select_where_found_screen_shown';
import Button, { ButtonKind } from 'bloko/blocks/button';
import HSpacing from 'bloko/blocks/hSpacing';
import { IconColor } from 'bloko/blocks/icon';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import Modal, { ModalContent, ModalFooter, ModalHeader, ModalTitle } from 'bloko/blocks/modal';
import VSpacing from 'bloko/blocks/vSpacing';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { LangTrls, TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import applicantJobSearchStatusSuccess from 'src/components/Notifications/ApplicantJobSearchStatusSuccess';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { hidePostChangeModal } from 'src/models/applicant/jobSearchStatus';
import { JobSearchStatus } from 'src/models/applicantUserStatuses';
import { TopLevelSite } from 'src/models/topLevelSite';

import FoundOnHHContent from 'src/components/Applicant/JobSearchStatus/PostChangeModal/FoundOnHHContent';
import OffersContent from 'src/components/Applicant/JobSearchStatus/PostChangeModal/OffersContent';
import ReasonsContent from 'src/components/Applicant/JobSearchStatus/PostChangeModal/ReasonsContent';
import WhatConfusedContent from 'src/components/Applicant/JobSearchStatus/PostChangeModal/WhatConfusedContent';
import {
    FoundOnHHVariants,
    ModalStep,
    ReasonVariants,
    Offer,
} from 'src/components/Applicant/JobSearchStatus/PostChangeModal/types';
import { useFetchPossibleJobOffers } from 'src/components/Applicant/JobSearchStatus/PostChangeModal/useFetchPossibleJobOffers';

import styles from './post-change-modal.less';

const TrlKeys = {
    title: {
        [ModalStep.FoundOnHHStep]: {
            [JobSearchStatus.HasJobOffer]: 'jobSearchStatus.postChangeModal.step.foundOnHH.has_job_offer',
            others: 'jobSearchStatus.postChangeModal.step.foundOnHH',
        },
        [ModalStep.ChooseOfferStep]: {
            [JobSearchStatus.HasJobOffer]: 'jobSearchStatus.postChangeModal.step.ChooseOfferStep.has_job_offer',
            others: 'jobSearchStatus.postChangeModal.step.ChooseOfferStep.others',
        },
        [ModalStep.ChooseReasonStep]: {
            [JobSearchStatus.HasJobOffer]: 'jobSearchStatus.postChangeModal.step.ChooseOfferStep.has_job_offer',
            others: 'jobSearchStatus.postChangeModal.step.ChooseOfferStep.others',
        },
        [ModalStep.WriteWhatConfused]: 'jobSearchStatus.postChangeModal.step.writeWhatConfused',
    },
    footer: {
        close: 'jobSearchStatus.postChangeModal.footer.close',
        anotherAnswer: 'jobSearchStatus.postChangeModal.footer.anotherAnswer',
        finish: 'jobSearchStatus.postChangeModal.footer.finish',
        answer: 'jobSearchStatus.postChangeModal.footer.answer',
        continue: 'jobSearchStatus.postChangeModal.footer.continue',
        done: 'jobSearchStatus.postChangeModal.footer.done',
    },
};

const getModalTitle = (
    step: ModalStep,
    trls: LangTrls,
    topLevelDomain: string | null,
    userStatus: JobSearchStatus
): string => {
    if (step === ModalStep.WriteWhatConfused) {
        return trls[TrlKeys.title[step]];
    }
    const statusKey = userStatus === JobSearchStatus.HasJobOffer ? JobSearchStatus.HasJobOffer : 'others';
    if (step === ModalStep.FoundOnHHStep) {
        return format(trls[TrlKeys.title[ModalStep.FoundOnHHStep][statusKey]], {
            '{0}': topLevelDomain ?? TopLevelSite.RU,
        });
    }
    return trls[TrlKeys.title[step][statusKey]];
};

const PostChangeModal: TranslatedComponent = ({ trls }) => {
    const dispatch = useDispatch();
    const { addNotification } = useNotification();
    const [foundOnHHValue, setFoundOnHHValue] = useState<FoundOnHHVariants | null>(null);
    const [reasonValue, setReasonValue] = useState<ReasonVariants | null>(null);
    const [modalStep, setModalStep] = useState<ModalStep>(ModalStep.FoundOnHHStep);
    const { loading, fetchOffers, cancelFetch } = useFetchPossibleJobOffers();
    const modalVisible = useSelector((state) => state.jobSearchStatusPostChangeModal?.visible);
    const showStatusChangeNotification = useSelector(
        (state) => state.jobSearchStatusPostChangeModal?.showStatusChangeNotification
    );
    const topLevelDomain = useSelector((state) => state.topLevelDomain);

    const userStatuses = useSelector((state) => state.applicantUserStatuses);
    const userStatus = userStatuses?.jobSearchStatus?.name || JobSearchStatus.AcceptedJobOffer;
    const userStatusAnalytics = userStatus.toUpperCase() as JobSearchStatusAnalytics;
    const [selectedOffers, setSelectedOffers] = useState<Offer[]>([]);
    const [whatConfusedText, setWhatConfusedText] = useState<string>('');
    const breakpoint = useBreakpoint(Breakpoint.XS);
    const isXs = breakpoint === Breakpoint.XS;

    useEffect(() => {
        if (modalVisible) {
            setFoundOnHHValue(null);
            setReasonValue(null);
            setModalStep(ModalStep.FoundOnHHStep);
            setSelectedOffers([]);
            setWhatConfusedText('');
            userStatusSelectWhereFoundScreenShown();
        }
    }, [modalVisible]);

    const hideModal = () => {
        dispatch(hidePostChangeModal());
        if (showStatusChangeNotification) {
            addNotification(applicantJobSearchStatusSuccess);
        }
    };

    const handleClose = () => {
        cancelFetch();
        hideModal();
    };

    const finishFirstStep = () => {
        userStatusSelectWhereFoundButtonClick({ offerFromHH: foundOnHHValue === FoundOnHHVariants.FoundOnHH });

        if (foundOnHHValue === FoundOnHHVariants.NotFoundOnHH) {
            handleClose();
            return;
        }

        fetchOffers({ setModalStep });
    };

    const finishChooseReason = () => {
        if (reasonValue) {
            userStatusSelectOfferCompanyEmptyButtonClick({ type: reasonValue });
        }

        handleClose();
    };

    const finishChooseOffers = (offers: Offer[]) => {
        offers.forEach(({ employerId, vacancyId, resumeId }) => {
            userStatusSelectOfferCompanyButtonClick({
                employerId: employerId.toString(),
                type: 'full',
                vacancyId: `${vacancyId}`,
                resumeId: `${resumeId}`,
                jobSearchStatus: userStatusAnalytics,
            });
        });
        hideModal();
    };

    // if HasJobOffer => can be multi choise, else (AcceptedJobOffer) => single offer only
    const addOffer = (employerId: number, vacancyId: number, resumeId: number) => {
        if (userStatus === JobSearchStatus.AcceptedJobOffer) {
            const offers = [{ employerId, vacancyId, resumeId }];
            setSelectedOffers([{ employerId, vacancyId, resumeId }]);

            // for single choice, close bottom sheet at once
            if (userStatus === JobSearchStatus.AcceptedJobOffer && isXs) {
                finishChooseOffers(offers);
            }
            return;
        }
        let newValue;
        if (selectedOffers.map((offer) => offer.vacancyId).includes(vacancyId)) {
            newValue = selectedOffers.filter((offer) => offer.vacancyId !== vacancyId);
        } else {
            newValue = [...selectedOffers, { employerId, vacancyId, resumeId }];
        }
        setSelectedOffers(newValue);
    };

    const sendWhatConfused = () => {
        userStatusSelectSendWhatConfused({ text: whatConfusedText });

        hideModal();
    };

    const closeButton = (
        <Button stretched={isXs} onClick={handleClose}>
            {trls[TrlKeys.footer.close]}
        </Button>
    );

    const STEP_CONTENT = {
        [ModalStep.FoundOnHHStep]: {
            content: <FoundOnHHContent value={foundOnHHValue} onSet={setFoundOnHHValue} />,
            footer: {
                mainAction: (
                    <Button
                        disabled={!foundOnHHValue}
                        onClick={finishFirstStep}
                        kind={ButtonKind.Primary}
                        stretched={isXs}
                        loading={loading && <Loading scale={LoadingScale.Small} initial={IconColor.White} />}
                    >
                        {trls[TrlKeys.footer.continue]}
                    </Button>
                ),
                secondaryAction: closeButton,
            },
        },
        [ModalStep.ChooseReasonStep]: {
            content: <ReasonsContent value={reasonValue} onSet={setReasonValue} />,
            footer: {
                mainAction: (
                    <Button
                        stretched={isXs}
                        disabled={!reasonValue}
                        onClick={finishChooseReason}
                        kind={ButtonKind.Primary}
                    >
                        {trls[TrlKeys.footer.done]}
                    </Button>
                ),
                secondaryAction: closeButton,
            },
        },
        [ModalStep.ChooseOfferStep]: {
            content: <OffersContent addOffer={addOffer} selectedOffers={selectedOffers} />,
            footer: {
                mainAction: (
                    <>
                        {!(userStatus === JobSearchStatus.AcceptedJobOffer && isXs) && (
                            <Button
                                stretched={isXs}
                                kind={ButtonKind.Primary}
                                disabled={selectedOffers.length === 0}
                                onClick={() => {
                                    finishChooseOffers(selectedOffers);
                                }}
                            >
                                {trls[TrlKeys.footer.finish]}
                            </Button>
                        )}
                    </>
                ),
                secondaryAction: (
                    <Button
                        stretched={isXs}
                        onClick={() => {
                            setModalStep(ModalStep.WriteWhatConfused);
                        }}
                    >
                        {trls[TrlKeys.footer.anotherAnswer]}
                    </Button>
                ),
            },
        },
        [ModalStep.WriteWhatConfused]: {
            content: <WhatConfusedContent value={whatConfusedText} setValue={setWhatConfusedText} />,
            footer: {
                mainAction: (
                    <Button stretched={isXs} kind={ButtonKind.Primary} onClick={sendWhatConfused}>
                        {trls[TrlKeys.footer.answer]}
                    </Button>
                ),
                secondaryAction: closeButton,
            },
        },
    };

    return (
        <Modal useBottomSheet visible={modalVisible} onClose={handleClose}>
            <ModalHeader>
                <ModalTitle>{getModalTitle(modalStep, trls, topLevelDomain, userStatus)}</ModalTitle>
            </ModalHeader>
            <ModalContent>
                <div className={styles.postChangeModalContent}>{STEP_CONTENT[modalStep].content}</div>
            </ModalContent>
            <ModalFooter>
                {STEP_CONTENT[modalStep].footer.secondaryAction}
                {isXs ? <VSpacing base={3} /> : <HSpacing base={2} />}
                {STEP_CONTENT[modalStep].footer.mainAction}
            </ModalFooter>
        </Modal>
    );
};

export default translation(PostChangeModal);
