import { useState, useEffect, useMemo } from 'react';

import { urlParser } from '@hh.ru/browser-api-utils';

import Debug from 'HHC/Debug';

import { useIsClient } from 'src/hooks/useIsClient';
import { useSelector } from 'src/hooks/useSelector';

interface SmartScriptProps {
    from?: string;
}

const DEFAULT_SMART_SCRIPT_PARAMS = {
    mediaSource: {
        defaultValue: 'web-to-app',
    },
};
export const ONLINK_FROM_PARAM_NAME = 'af_sub5';

export const useSmartScript = ({ from }: SmartScriptProps = {}) => {
    const [link, setLink] = useState<string | null>(null);
    const smartScript = useSelector(({ smartScript }) => smartScript);
    const appLink = useSelector(({ appLink }) => appLink);
    const topLevelDomain = useSelector(({ topLevelDomain }) => topLevelDomain);
    const request = useSelector(({ request }) => request);
    const isClient = useIsClient();

    useEffect(() => {
        if (smartScript.isAvailable && window?.AF_SMART_SCRIPT) {
            try {
                const generatedLink = window.AF_SMART_SCRIPT.generateOneLinkURL({
                    oneLinkURL: smartScript.href,
                    afParameters: {
                        ...DEFAULT_SMART_SCRIPT_PARAMS,
                        ...smartScript.params,
                    },
                });
                generatedLink?.clickURL && setLink(generatedLink?.clickURL);
            } catch (error) {
                Debug.log('out error', error);
            }
        }
    }, [smartScript.isAvailable, smartScript.params, smartScript.href, topLevelDomain]);

    return useMemo(() => {
        if (!appLink?.detected && !link && smartScript.href) {
            const parsedSmartScriptHref = urlParser(smartScript.href);

            parsedSmartScriptHref.params.pid = DEFAULT_SMART_SCRIPT_PARAMS.mediaSource.defaultValue;
            parsedSmartScriptHref.params[ONLINK_FROM_PARAM_NAME] = from;

            return parsedSmartScriptHref.href;
        }

        const parsedAppLink = urlParser(link || appLink.href);

        if (!parsedAppLink.params.pid) {
            parsedAppLink.params.pid = DEFAULT_SMART_SCRIPT_PARAMS.mediaSource.defaultValue;
        }

        if (from) {
            parsedAppLink.params[ONLINK_FROM_PARAM_NAME] = from;
        }

        if (!isClient) {
            parsedAppLink.params.default = 1;
        }

        if (topLevelDomain) {
            parsedAppLink.params.domain = topLevelDomain;
        }

        if (smartScript.deepLink) {
            parsedAppLink.params.deep_link_value = `${request.protocol}://${topLevelDomain}${smartScript.deepLink}`;
        }

        return parsedAppLink.href;
    }, [
        appLink?.detected,
        appLink.href,
        link,
        smartScript.href,
        smartScript.deepLink,
        from,
        isClient,
        topLevelDomain,
        request.protocol,
    ]);
};
