import { FC, useRef, useEffect, ReactNode, useMemo } from 'react';
import classNames from 'classnames';

import footerLinkClick from '@hh.ru/analytics-js-events/build/xhh/common/footer_link_click';
import { Text, VSpacing } from '@hh.ru/magritte-ui';

import footerAnalytics from 'src/components/Footer/analytics';
import { SupernovaLinkKind } from 'src/components/SupernovaMainMenu/Link';
import { useFeatureEnabled } from 'src/hooks/useFeatureEnabled';
import { NavItem } from 'src/models/supernovaNaviMenu';

import ChatBot from 'src/components/Footer/NavItems/ChatBot';
import Lang from 'src/components/Footer/NavItems/Lang';
import NavItemLink from 'src/components/Footer/NavItems/NavItemLink';

import styles from './index.less';

interface NaviItemsProps {
    nav?: NavItem;
    showHeader?: boolean;
    isMobileFriendly?: boolean;
    linkKind?: SupernovaLinkKind;
    direction?: 'vertical' | 'horizontal';
    spacing?: ReactNode;
}

const Features = {
    employerMainPageAnalyticsEnabled: 'employer_main_page_analytics_enabled',
};

const NaviItems: FC<NaviItemsProps> = ({
    nav,
    showHeader = false,
    isMobileFriendly = false,
    linkKind = SupernovaLinkKind.MagritteNeutralSecondary,
    direction = 'vertical',
    spacing,
}) => {
    const employerMainPageAnalyticsEnabled = useFeatureEnabled(Features.employerMainPageAnalyticsEnabled);
    const navLinksRef = useRef(null);
    const items = useMemo(() => nav?.subItems || [], [nav?.subItems]);
    const navName = nav?.name || '';
    const handleLinkClick = (name: string) => {
        footerLinkClick({ type: name });
        if (employerMainPageAnalyticsEnabled) {
            footerAnalytics.navigationItemClick(name);
        }
    };

    useEffect(() => {
        if (employerMainPageAnalyticsEnabled && items?.length && navLinksRef.current) {
            footerAnalytics.navigationItemsShown(
                navLinksRef.current,
                navName,
                items.map((item) => item.name)
            );
        }
    }, [employerMainPageAnalyticsEnabled, items, navName]);

    if (!nav || items?.length === 0) {
        return null;
    }

    return (
        <>
            {showHeader && (
                <>
                    <Text typography="label-2-regular">{nav.translations.name}</Text>
                    <VSpacing default={12} />
                </>
            )}

            <ul
                ref={navLinksRef}
                className={classNames(styles.navItemsWrapper, {
                    [styles.horizontal]: direction === 'horizontal',
                })}
            >
                {items?.map((menu) => {
                    let content = null;

                    switch (menu.name) {
                        case 'lang':
                            return <Lang key={menu.name} onClick={() => handleLinkClick(menu.name)} {...menu} />;
                        case 'chatBot':
                            content = <ChatBot {...menu} />;
                            break;
                        default:
                            content = (
                                <NavItemLink
                                    dataQa={`footer_${menu.name}${isMobileFriendly ? '-xs' : ''}`}
                                    kind={linkKind}
                                    onClick={() => handleLinkClick(menu.name)}
                                    {...menu}
                                />
                            );
                    }

                    return <li key={menu.name}>{content}</li>;
                })}
            </ul>

            {spacing}
        </>
    );
};

export default NaviItems;
