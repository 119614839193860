import { useEffect, useState, useRef, MutableRefObject } from 'react';

import failedCloseButtonClick, {
    HhtmSource,
} from '@hh.ru/analytics-js-events/build/xhh/employer/calls/web_call/call_alert_close_button_click';
import callTypeAlertShown from '@hh.ru/analytics-js-events/build/xhh/employer/calls/web_call/call_alert_element_shown';
import micAlertShown from '@hh.ru/analytics-js-events/build/xhh/employer/calls/web_call/mic_hh_alert_element_shown';

import { useNotification } from 'src/components/Notifications/Provider';
import { webcallFailedToCall, type WebcallFailedToCallProps } from 'src/components/Notifications/WebCall';

import { WEBCALL_CUSTOM_EVENTS } from 'src/components/Webcall/const';

type AnalyticsCallType = 'phone' | 'in-app' | null;

interface HookProps {
    closeFn: () => void;
    widgetId: string;
    topicId?: string;
    resumeId: number;
    source: HhtmSource;
    callType?: AnalyticsCallType;
    canSwitchCallType?: boolean;
}

const MIC_DISABLED_ERROR = 'Media access denied';
const RESUME_DATA_ERROR = 'resume_load_failure';

interface HookResult {
    isMicPermissionError: boolean;
    micAlertRef: MutableRefObject<null>;
    callTypeAlertRef: MutableRefObject<null>;
    isUserDataError: boolean;
    resetUserDataError: VoidFunction;
    isCallTypeAlertShown: boolean;
    resetCallTypeAlert: VoidFunction;
}

// this hook is listening for webcall close event and shows alerts when needed
const useWatchWebcallClose = function ({
    closeFn,
    widgetId,
    topicId,
    resumeId,
    source,
    callType,
    canSwitchCallType,
}: HookProps): HookResult {
    const { addNotification } = useNotification();
    const [isMicPermissionError, setIsMicPermissionError] = useState(false);
    const [isCallTypeAlertShown, setCallTypeAlertShown] = useState(false);
    const [isUserDataError, setIsUserDataError] = useState(false);
    const [isCallTypeSuggestionDone, setCallTypeSuggestionDone] = useState(false);
    const micAlertRef = useRef(null);
    const callTypeAlertRef = useRef(null);

    useEffect(() => {
        const handleClose = (
            evt: CustomEvent<{
                error?: string;
                widgetId?: string;
                webCallSessionId?: string;
                isConnectFailed?: boolean;
            }>
        ) => {
            let shouldClose = true;
            const isCurrentWidget = evt.detail.widgetId === widgetId;
            const shouldShowSwitchTypeAlert = evt.detail.isConnectFailed && isCurrentWidget && canSwitchCallType;
            if (evt.detail.error && isCurrentWidget) {
                if (evt.detail.error === MIC_DISABLED_ERROR) {
                    shouldClose = false;
                    setIsMicPermissionError(true);
                    if (micAlertRef.current !== null) {
                        micAlertShown(micAlertRef.current as HTMLDivElement, {
                            hhtmSource: source,
                            resumeId,
                            topicId,
                            callType,
                        });
                    }
                } else if (evt.detail.error === RESUME_DATA_ERROR) {
                    shouldClose = false;
                    setIsUserDataError(true);
                } else {
                    addNotification(webcallFailedToCall, {
                        props: {
                            onClose: () => {
                                failedCloseButtonClick({
                                    hhtmSource: source,
                                    resumeId,
                                    topicId,
                                    callType,
                                });
                            },
                            hhtmSource: source,
                            topicId,
                            resumeId,
                            webCallSessionId: evt.detail.webCallSessionId,
                            error: evt.detail.error,
                            callType,
                        } as WebcallFailedToCallProps,
                    });
                }
            } else if (shouldShowSwitchTypeAlert) {
                if (!isCallTypeSuggestionDone) {
                    setCallTypeAlertShown(true);
                    setCallTypeSuggestionDone(true);
                    shouldClose = false;
                    if (callTypeAlertRef.current !== null && callType) {
                        callTypeAlertShown(callTypeAlertRef.current as HTMLDivElement, {
                            hhtmSource: source,
                            resumeId,
                            topicId,
                            callType,
                            errorType: 'disconnect',
                        });
                    }
                }
            }

            if (shouldClose) {
                closeFn();
            }
        };

        window.addEventListener(WEBCALL_CUSTOM_EVENTS.CLOSE, handleClose as EventListener);
        return () => window.removeEventListener(WEBCALL_CUSTOM_EVENTS.CLOSE, handleClose as EventListener);
    }, [
        closeFn,
        addNotification,
        setIsMicPermissionError,
        resumeId,
        source,
        topicId,
        widgetId,
        callType,
        isCallTypeSuggestionDone,
        isCallTypeAlertShown,
        canSwitchCallType,
    ]);

    return {
        isMicPermissionError,
        isUserDataError,
        resetUserDataError: () => {
            setIsUserDataError(false);
        },
        micAlertRef,
        isCallTypeAlertShown,
        resetCallTypeAlert: () => {
            setCallTypeAlertShown(false);
        },
        callTypeAlertRef,
    };
};

export { useWatchWebcallClose };
