import { useCallback } from 'react';

import {
    Action,
    Button,
    ButtonProps,
    Modal,
    BottomSheet,
    NavigationBar,
    BottomSheetFooter,
    useBreakpoint,
    Input,
    VSpacing,
    Text,
    ActionBar,
} from '@hh.ru/magritte-ui';
import { ChevronLeftOutlinedSize24, CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AutosearchMessengers from 'src/components/AutosearchMessengers';
import SubscriptionAlert from 'src/components/SupernovaSearch/VacancySavedSearchButton/SubscriptionAlert';
import { SaveSearchError, FormState } from 'src/components/SupernovaSearch/VacancySavedSearchButton/types';
import translation from 'src/components/translation';
import { useOnOffState } from 'src/hooks/useOnOffState';

import { Props } from 'src/components/SupernovaSearch/VacancySavedSearchButton/AutosearchModal/types';

import styles from './styles.less';

const TrlKeys = {
    buttonClose: 'autosearch.autosearchModal.button.close',
    buttonSubscribe: 'autosearch.autosearchModal.button.subscribe',
    modalMessengersTitle: 'autosearch.autosearchModal.messengers.title',
    modalEmailTitle: 'autosearch.autosearchModal.email.title',
    inputPlaceholder: 'autosearch.autosearchModal.inputPlaceholder',
    bottomSheetTitle: 'autosearch.autosearchModal.bottomSheetTitle',
    bottomSheetMainText: 'search.vacancy.savedSearch.form.title',
    buttonEmail: 'search.vacancy.savedSearch.form.button.email',
    buttonMessengers: 'search.vacancy.savedSearch.form.button.messengers',
    emailError: {
        [SaveSearchError.WrongEmail]: 'search.vacancy.savedSearch.form.error.email.wrong',
        [SaveSearchError.DuplicateEmail]: 'search.vacancy.savedSearch.form.error.email.duplicate',
    },
    bottomSheetOnlyEmailTitle: 'search.vacancy.savedSearch.tooltip.onlyEmail.title',
    bottomSheetOnlyEmailButtonText: 'search.vacancy.savedSearch.tooltip.onlyEmail.button',
};

const AutosearchModal: TranslatedComponent<Props> = ({
    trls,
    isDropVisible,
    onDropClose,
    isModalEmailFormVisible,
    isModalMessengersVisible,
    hideModal,
    existChatBot,
    userEmail,
    emailInputValue,
    onEmailInputChange,
    onClickEmailSubscribe,
    emailError,
    formState,
    isLoading,
    withoutMainContent,
}) => {
    const { isMobile } = useBreakpoint();
    const [emailContentVisible, setEmailContentVisible, setEmailContentHidden] = useOnOffState(false);
    const [messengersContentVisible, setMessengersContentVisible, setMessengersContentHidden] = useOnOffState(false);

    const existEmail = !!userEmail;

    const handleHideSelectedContent = useCallback(() => {
        setEmailContentHidden();
        setMessengersContentHidden();
    }, [setEmailContentHidden, setMessengersContentHidden]);

    const handleSaveOnEmailButtonClick = useCallback(() => {
        if (existEmail) {
            onClickEmailSubscribe();
        } else {
            setEmailContentVisible();
        }
    }, [existEmail, onClickEmailSubscribe, setEmailContentVisible]);

    const handleMobileClose = useCallback(() => {
        onDropClose();
        handleHideSelectedContent();
    }, [onDropClose, handleHideSelectedContent]);

    const showBackButton = (emailContentVisible || messengersContentVisible) && !withoutMainContent;

    const closeButtonProps: Pick<ButtonProps, 'style' | 'onClick' | 'data-qa'> = {
        style: 'accent',
        onClick: hideModal,
        'data-qa': 'autosearch-modal-close',
    };

    const isModalVisible = isModalEmailFormVisible || isModalMessengersVisible;
    const isMessengersMode = isModalMessengersVisible;
    const showMainContent = !emailContentVisible && !messengersContentVisible && !withoutMainContent;
    const alertVisible = !!formState && formState !== FormState.Saving;
    const alertError = formState === FormState.Error;

    const emailIsTheOnlyOption = !existChatBot;
    const bottomSheetMainText =
        trls[TrlKeys[emailIsTheOnlyOption ? 'bottomSheetOnlyEmailTitle' : 'bottomSheetMainText']];
    const bottomSheetEmailButtonText =
        trls[TrlKeys[emailIsTheOnlyOption ? 'bottomSheetOnlyEmailButtonText' : 'buttonEmail']];

    return (
        <>
            <Modal
                visible={isModalVisible}
                onClose={hideModal}
                size="small"
                title={trls[TrlKeys[isMessengersMode ? 'modalMessengersTitle' : 'modalEmailTitle']]}
                titleSize="medium"
                footer={
                    <ActionBar
                        primaryActions={
                            isMessengersMode ? (
                                <Button mode="primary" {...closeButtonProps}>
                                    {trls[TrlKeys.buttonClose]}
                                </Button>
                            ) : (
                                <Button
                                    mode="primary"
                                    style="accent"
                                    onClick={onClickEmailSubscribe}
                                    loading={isLoading}
                                    disabled={emailInputValue === '' || !!emailError}
                                    data-qa="autosearch-modal-email-subscribe"
                                >
                                    {trls[TrlKeys.buttonSubscribe]}
                                </Button>
                            )
                        }
                        secondaryActions={
                            isMessengersMode ? undefined : (
                                <Button mode="tertiary" {...closeButtonProps}>
                                    {trls[TrlKeys.buttonClose]}
                                </Button>
                            )
                        }
                    />
                }
            >
                {isMessengersMode ? (
                    <AutosearchMessengers />
                ) : (
                    <Input
                        placeholder={trls[TrlKeys.inputPlaceholder]}
                        value={emailInputValue}
                        onChange={onEmailInputChange}
                        invalid={!!emailError}
                        errorMessage={!!emailError && trls[TrlKeys.emailError[emailError]]}
                        size="large"
                        type="email"
                        elevatePlaceholder
                        data-qa="autosearch-subscribe__email"
                    />
                )}
            </Modal>
            <BottomSheet
                visible={isMobile && isDropVisible}
                onClose={handleMobileClose}
                keyboardOverlaysFooter
                header={
                    <NavigationBar
                        title={trls[TrlKeys.bottomSheetTitle]}
                        left={
                            showBackButton ? (
                                <Action icon={ChevronLeftOutlinedSize24} onClick={handleHideSelectedContent} />
                            ) : undefined
                        }
                        right={<Action icon={CrossOutlinedSize24} onClick={handleMobileClose} />}
                    />
                }
                footer={
                    (showMainContent || emailContentVisible || (withoutMainContent && isModalEmailFormVisible)) && (
                        <BottomSheetFooter>
                            {showMainContent ? (
                                <>
                                    <Button
                                        mode="secondary"
                                        style="accent"
                                        loading={isLoading}
                                        onClick={handleSaveOnEmailButtonClick}
                                        stretched
                                    >
                                        {bottomSheetEmailButtonText}
                                    </Button>
                                    {!emailIsTheOnlyOption && (
                                        <Button
                                            mode="secondary"
                                            style="accent"
                                            onClick={setMessengersContentVisible}
                                            stretched
                                        >
                                            {trls[TrlKeys.buttonMessengers]}
                                        </Button>
                                    )}
                                </>
                            ) : (
                                <Button
                                    mode="primary"
                                    style="accent"
                                    onClick={onClickEmailSubscribe}
                                    loading={isLoading}
                                    disabled={emailInputValue === '' || !!emailError}
                                    data-qa="autosearch-modal-email-subscribe"
                                    stretched
                                >
                                    {trls[TrlKeys.buttonSubscribe]}
                                </Button>
                            )}
                        </BottomSheetFooter>
                    )
                }
            >
                {showMainContent && (
                    <>
                        <div className={styles.bottomSheetText}>
                            <Text typography="label-1-regular">{bottomSheetMainText}</Text>
                        </div>
                        <VSpacing default={60} />
                    </>
                )}
                {(emailContentVisible || (withoutMainContent && isModalEmailFormVisible)) && (
                    <Input
                        placeholder={trls[TrlKeys.inputPlaceholder]}
                        value={emailInputValue}
                        onChange={onEmailInputChange}
                        invalid={!!emailError}
                        errorMessage={!!emailError && trls[TrlKeys.emailError[emailError]]}
                        size="large"
                        type="email"
                        elevatePlaceholder
                        data-qa="autosearch-subscribe__email"
                    />
                )}
                {(messengersContentVisible || (withoutMainContent && isModalMessengersVisible)) && (
                    <AutosearchMessengers isXS={isMobile} />
                )}
            </BottomSheet>
            <SubscriptionAlert
                visible={alertVisible}
                onClose={handleMobileClose}
                email={emailInputValue || userEmail}
                hasError={alertError}
            />
        </>
    );
};

export default translation(AutosearchModal);
