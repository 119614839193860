import { Fragment, useEffect, useRef } from 'react';

import userStatusSelectOpenButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/user_status/user_status_select_open_button_click';
import userStatusSelectOpenElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/user_status/user_status_select_open_element_shown';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { SupernovaUserNotificationsIconKind } from 'src/components/SupernovaMainMenu/UserNotifications/Icon';
import NotificationLink from 'src/components/SupernovaMainMenu/UserNotifications/Link';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import NotificationSpacer from 'src/components/SupernovaMainMenu/UserNotifications/Spacer';
import { DataQaInvariants } from 'src/components/SupernovaMainMenu/UserNotifications/constants';
import translation from 'src/components/translation';
import { useExperiment } from 'src/hooks/useExperiment';
import {
    ComplexUserNotification,
    ComplexUserNotificationsTemplateKey,
    UserNotificationsParams,
} from 'src/models/userNotifications';

const TrlKeys = {
    [ComplexUserNotificationsTemplateKey.PricesIncrease]: {
        content: 'supernova.notification.prices.increase',
        link: 'supernova.notification.prices.increase.more',
    },
    [ComplexUserNotificationsTemplateKey.ResumeBlocked]: {
        content: 'supernova.notification.resume.blocked.description',
        link: 'supernova.notification.resume.blocked.button',
    },
    [ComplexUserNotificationsTemplateKey.ResumeTargetEmployerDiscount]: {
        content: 'supernova.notification.resumeservices.wantwork.discount.text',
        link: 'supernova.notification.resumeservices.wantwork.discount.link',
    },
    [ComplexUserNotificationsTemplateKey.VideoCall]: {
        content: 'video.call.info.supernova.notification',
        link: 'video.call.info.supernova.notification.link',
    },
    [ComplexUserNotificationsTemplateKey.ApplicantJobSearchStatus]: {
        content: 'supernova.notification.applicant.job.search.status',
        link: 'supernova.notification.applicant.job.search.status.link',
    },
    [ComplexUserNotificationsTemplateKey.EmployerYearResult]: {
        content: 'supernova.notification.employer.year.results.text',
        link: 'supernova.notification.employer.year.results.link',
    },
    [ComplexUserNotificationsTemplateKey.AutoSearchVacancies]: {
        content: 'supernova.notification.new.vacancies.in.autosearch.1',
        link: 'supernova.notification.new.vacancies.in.autosearch.2',
    },
};

type TemplateKeys = keyof typeof TrlKeys;

type LinkUrlMap = Record<TemplateKeys, (params: UserNotificationsParams) => string>;

const LINK_URL_MAP: LinkUrlMap = {
    [ComplexUserNotificationsTemplateKey.PricesIncrease]: () => '/article/25341',
    [ComplexUserNotificationsTemplateKey.ResumeBlocked]: (params) =>
        `/applicant/resumes/completion?resume=${params.resumeHash}`,
    [ComplexUserNotificationsTemplateKey.ResumeTargetEmployerDiscount]: () => '/employers_list',
    [ComplexUserNotificationsTemplateKey.VideoCall]: () => '/article/26862',
    [ComplexUserNotificationsTemplateKey.EmployerYearResult]: () => '/article/stats2022',
    [ComplexUserNotificationsTemplateKey.AutoSearchVacancies]: () => '/applicant/autosearch',
    [ComplexUserNotificationsTemplateKey.ApplicantJobSearchStatus]: () =>
        '/applicant/resumes?set_job_search_status=true',
};

// Нужен для экспа profile_job_search_status
const LINK_URL_MAP_NEW: LinkUrlMap = {
    [ComplexUserNotificationsTemplateKey.PricesIncrease]: () => '/article/25341',
    [ComplexUserNotificationsTemplateKey.ResumeBlocked]: (params) =>
        `/applicant/resumes/completion?resume=${params.resumeHash}`,
    [ComplexUserNotificationsTemplateKey.ResumeTargetEmployerDiscount]: () => '/employers_list',
    [ComplexUserNotificationsTemplateKey.VideoCall]: () => '/article/26862',
    [ComplexUserNotificationsTemplateKey.EmployerYearResult]: () => '/article/stats2022',
    [ComplexUserNotificationsTemplateKey.AutoSearchVacancies]: () => '/applicant/autosearch',
    [ComplexUserNotificationsTemplateKey.ApplicantJobSearchStatus]: () => '/profile/me?set_job_search_status=true',
};

type LinkClickMap = Partial<Record<TemplateKeys, () => void>>;

const LINK_CLICK_MAP: LinkClickMap = {
    [ComplexUserNotificationsTemplateKey.ApplicantJobSearchStatus]: () => {
        userStatusSelectOpenButtonClick();
    },
};

type LinkShownMap = Partial<Record<TemplateKeys, (element: HTMLAnchorElement) => void>>;

const LINK_SHOWN_MAP: LinkShownMap = {
    [ComplexUserNotificationsTemplateKey.ApplicantJobSearchStatus]: (element) => {
        userStatusSelectOpenElementShown(element);
    },
};

const DATA_QA_MAP: Record<string, DataQaInvariants> = {
    [ComplexUserNotificationsTemplateKey.ResumeTargetEmployerDiscount]: DataQaInvariants.ResumeTargetEmployerDiscount,
    [ComplexUserNotificationsTemplateKey.VideoCall]: DataQaInvariants.Info,
    [ComplexUserNotificationsTemplateKey.EmployerYearResult]: DataQaInvariants.ChristmasTree,
    [ComplexUserNotificationsTemplateKey.AutoSearchVacancies]: DataQaInvariants.Info,
    [ComplexUserNotificationsTemplateKey.ApplicantJobSearchStatus]: DataQaInvariants.Info,
};

const ICON_KIND_MAP: Record<string, SupernovaUserNotificationsIconKind> = {
    [ComplexUserNotificationsTemplateKey.ResumeTargetEmployerDiscount]: SupernovaUserNotificationsIconKind.Mail,
    [ComplexUserNotificationsTemplateKey.VideoCall]: SupernovaUserNotificationsIconKind.New,
    [ComplexUserNotificationsTemplateKey.EmployerYearResult]: SupernovaUserNotificationsIconKind.ChristmasTree,
    [ComplexUserNotificationsTemplateKey.AutoSearchVacancies]: SupernovaUserNotificationsIconKind.Info,
    [ComplexUserNotificationsTemplateKey.ApplicantJobSearchStatus]: SupernovaUserNotificationsIconKind.Info,
};

const DefaultInvariant: TranslatedComponent<ComplexUserNotification> = ({ trls, ...notification }) => {
    const key = notification.templateKey as TemplateKeys;
    const Wrapper = key === ComplexUserNotificationsTemplateKey.AutoSearchVacancies ? Fragment : NotificationSpacer;
    const linkRef = useRef(null);

    const isProfileJobSearchStatus = useExperiment('profile_job_search_status');

    useEffect(() => {
        if (linkRef.current) {
            LINK_SHOWN_MAP[key]?.(linkRef.current);
        }
    }, [key]);

    const linkUrlMap = isProfileJobSearchStatus ? LINK_URL_MAP_NEW : LINK_URL_MAP;

    return (
        <Notification iconKind={ICON_KIND_MAP[key]} dataQa={DATA_QA_MAP[key]} {...notification}>
            <Wrapper>
                <span data-qa="notification-content">{trls[TrlKeys[key].content]}</span>
            </Wrapper>
            <NotificationLink to={linkUrlMap[key](notification.params)} onClick={LINK_CLICK_MAP[key]} ref={linkRef}>
                {trls[TrlKeys[key].link]}
            </NotificationLink>
        </Notification>
    );
};

export default translation(DefaultInvariant);
