// tempexp_PORTFOLIO-40783_file

import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface VacancyBenefit {
    id: number;
    text: string;
}

export const vacancyBenefits = autoGeneratedReducer<VacancyBenefit[]>([]);
