import React from 'react';

import { LinkProps } from '@hh.ru/magritte-ui';

import SupernovaLink from 'src/components/SupernovaMainMenu/Link';
import { TrackClicksProps, useTrackClicks } from 'src/components/SupernovaMainMenu/useTrackClicks';

interface NaviLinkProps extends TrackClicksProps {
    active?: boolean;
    name: string;
    onClick?: React.MouseEventHandler;
    url: string;
    isMagritte?: boolean;
    magritteTypography?: LinkProps['typography'];
}

const ACTIVE_ATTRS = {
    disabled: true,
    tabIndex: -1,
};

const Link: React.FC<NaviLinkProps & React.PropsWithChildren> = ({
    active,
    children,
    name,
    onClick,
    trackClicks,
    url,
    isMagritte,
    magritteTypography,
    analytics,
    ...props
}) => {
    const handleTrackClick = useTrackClicks(name, trackClicks, analytics);

    const handleClick = (event: React.MouseEvent) => {
        onClick && onClick(event);
        handleTrackClick();
    };

    return (
        <SupernovaLink
            isMagritte={isMagritte}
            magritteTypography={magritteTypography}
            onClick={handleClick}
            to={url}
            {...(active ? ACTIVE_ATTRS : {})}
            {...props}
        >
            {children}
        </SupernovaLink>
    );
};

export default Link;
