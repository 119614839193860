import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

export enum LoyaltySurveyType {
    Adv = 'ADV',
    Branding = 'BRANDING',
    Clickme = 'CLICKME',
    MainServices = 'MAIN_SERVICES',
}

interface LoyaltySurvey {
    id?: number;
    showNotification: boolean;
    showBanner: boolean;
    type: LoyaltySurveyType;
    runSurvey: boolean;
}

const RUN_LOYALTY_SURVEY = 'RUN_LOYALTY_SURVEY';

interface PayloadTypes {
    [RUN_LOYALTY_SURVEY]: boolean;
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const runLoyaltySurvey = actionCreator(RUN_LOYALTY_SURVEY);

const initialState = {
    showNotification: false,
    showBanner: false,
    type: LoyaltySurveyType.MainServices,
    runSurvey: false,
};

const loyaltySurvey = createReducer<LoyaltySurvey, PayloadTypes>(initialState, {
    [RUN_LOYALTY_SURVEY]: (state, { payload }) => {
        return { ...state, runSurvey: payload };
    },
});

export { loyaltySurvey };
