import React, { useRef } from 'react';

import { Link } from '@hh.ru/magritte-ui';
import { ChevronDownOutlinedSize16 } from '@hh.ru/magritte-ui/icon';

import SupernovaMagritteDropdown from 'src/components/SupernovaMainMenu/SupernovaMagritteDropdown';
import SupernovaNaviItem from 'src/components/SupernovaMainMenu/SupernovaNaviItem';
import { useTrackClicks } from 'src/components/SupernovaMainMenu/useTrackClicks';
import { useOnOffState } from 'src/hooks/useOnOffState';
import { NavItem } from 'src/models/supernovaNaviMenu';

const UserTypeDrop: React.FC<NavItem> = ({ name, trackClicks, subItems, analytics }) => {
    const activatorRef = useRef<HTMLDivElement>(null);
    const [isDropVisible, openDrop, closeDrop] = useOnOffState(false);

    const handleTrackClick = useTrackClicks(name, trackClicks, analytics);

    const handleClick = () => {
        handleTrackClick();
        openDrop();
    };

    const activeName = subItems?.find(({ active }) => active)?.translations.name;

    return (
        <SupernovaNaviItem ref={activatorRef} userTypeSwitcher>
            <Link
                typography="label-3-regular"
                style="neutral"
                Element="button"
                data-qa={`mainmenu_${name}`}
                onClick={handleClick}
                iconRight={<ChevronDownOutlinedSize16 />}
            >
                {activeName}
            </Link>
            <SupernovaMagritteDropdown
                activatorRef={activatorRef}
                onClose={closeDrop}
                visible={isDropVisible}
                items={subItems || []}
            />
        </SupernovaNaviItem>
    );
};

export default UserTypeDrop;
