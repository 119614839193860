import { useEffect, useCallback, useRef, useState } from 'react';

export type SetStateWithCallbackFunc<T> = (
    state: T,
    callback?: () => void,
    options?: SetStateWithCallbackFuncOptionalProps
) => void;

export interface SetStateWithCallbackFuncOptionalProps {
    force?: boolean;
}

const useSetStateWithCallback = <T>(initialState: T): [state: T, setState: SetStateWithCallbackFunc<T>] => {
    const callbacks = useRef<Array<() => void>>([]);
    const mutableState = useRef(initialState);
    const [state, setState] = useState(initialState);

    useEffect(() => {
        callbacks.current.forEach((callback) => callback());
        callbacks.current = [];
    }, [state]);

    const stateAction: SetStateWithCallbackFunc<T> = useCallback(
        (newState, callback, { force } = {}) => {
            if (newState !== mutableState.current) {
                callback && callbacks.current.push(callback);
                setState(newState);
                mutableState.current = newState;
            } else if (force ?? true) {
                callback?.();
            }
        },
        [setState]
    );

    return [state, stateAction];
};

export { useSetStateWithCallback };
