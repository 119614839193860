import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { markUserNotificationsAsViewed } from 'Modules/UserNotifications';
import { useSelector } from 'src/hooks/useSelector';
import {
    ComplexUserNotification,
    isComplexUserNotification,
    markUserNotificationsAsViewedByIds,
} from 'src/models/userNotifications';

interface UseNotifications {
    userNotifications: ComplexUserNotification[];
    markNotificationsAsViewed: () => void;
    unreadNotificationsCount: number;
}

const useNotifications = (): UseNotifications => {
    const dispatch = useDispatch();

    const userNotifications = useSelector(({ userNotifications }) => userNotifications);
    const supernovaComplexNotifications = useMemo(
        () =>
            userNotifications.filter(
                (notification): notification is ComplexUserNotification =>
                    isComplexUserNotification(notification) && notification.isSupernova
            ),
        [userNotifications]
    );

    return useMemo(() => {
        const unreadNotificationIds = supernovaComplexNotifications.filter(({ viewed }) => !viewed).map(({ id }) => id);

        const markNotificationsAsViewed = () => {
            if (unreadNotificationIds.length > 0) {
                void markUserNotificationsAsViewed(unreadNotificationIds);
                dispatch(markUserNotificationsAsViewedByIds(unreadNotificationIds));
            }
        };

        return {
            userNotifications: supernovaComplexNotifications,
            markNotificationsAsViewed,
            unreadNotificationsCount: unreadNotificationIds.length,
        };
    }, [dispatch, supernovaComplexNotifications]);
};

export { useNotifications };
