import { useCallback, useEffect, useState } from 'react';

import { usePush } from '@hh.ru/redux-spa-middleware';

import { useIsClient } from 'src/hooks/useIsClient';

import {
    applicantResumeClone,
    applicantResumeImport,
    getZpImportHHResumes,
    getZpImportResumes,
} from 'src/components/ImportResumeModal/api';
import { ImportResume, Step } from 'src/components/ImportResumeModal/types';
import { getUrl } from 'src/components/ImportResumeModal/utils';

export const useHHAuth = (): string => {
    const isClient = useIsClient();
    return isClient ? getUrl() : '';
};

export const useZpImportHHResumes = (setStep: (step: Step) => void): ImportResume[] => {
    const [resumes, setResumes] = useState<ImportResume[]>([]);

    useEffect(() => {
        void (async () => {
            try {
                const data = await getZpImportHHResumes();
                setResumes(data.items.map((item) => ({ label: item.title, value: item.id })));
            } catch (_) {
                setStep(Step.Error);
            }
        })();
    }, [setStep]);

    return resumes;
};

export const useZpImportResumes = (setStep: (step: Step) => void): ImportResume[] => {
    const [resumes, setResumes] = useState<ImportResume[]>([]);

    useEffect(() => {
        void (async () => {
            try {
                const data = await getZpImportResumes();
                const result = data.resumes.map((resume) => {
                    return { value: resume._attributes.hash, label: resume.title[0]?.string || '' };
                });
                setResumes(result);
            } catch (_) {
                setStep(Step.Error);
            }
        })();
    }, [setStep]);

    return resumes;
};

export const useCopyStep = (
    onClose: () => void
): [
    { copyValue: string; copyError: boolean },
    {
        onCopyAgain: () => void;
        onCopySelect: () => Promise<void>;
        onSetCopyValue: (value: string) => void;
        resetCopyError: () => void;
    },
] => {
    const push = usePush();

    const [copyValue, setCopyValue] = useState('');
    const [copyError, setCopyError] = useState(false);

    const onCopyAgain = () => {
        setCopyValue('');
        setCopyError(false);
    };

    const onCopySelect = async () => {
        if (!copyValue) {
            return;
        }
        try {
            const result = await applicantResumeClone(copyValue);
            const url = result.data.url;
            onClose();
            push(url);
        } catch (_) {
            setCopyError(true);
        }
    };

    const onSetCopyValue = useCallback((value: string) => {
        setCopyValue(value);
    }, []);

    const resetCopyError = useCallback(() => {
        setCopyError(false);
    }, []);

    const data = { copyValue, copyError };

    const handlers = {
        onCopyAgain,
        onCopySelect,
        onSetCopyValue,
        resetCopyError,
    };

    return [data, handlers];
};

export const useImportStep = (
    onClose: () => void
): [
    { importValue: string; importError: boolean },
    {
        onImportAgain: () => void;
        onImportSelect: () => Promise<void>;
        onSetImportValue: (value: string) => void;
        resetImportError: () => void;
    },
] => {
    const push = usePush();

    const [importValue, setImportValue] = useState('');
    const [importError, setImportError] = useState(false);

    const onImportAgain = () => {
        setImportValue('');
        setImportError(false);
    };

    const onImportSelect = async () => {
        if (!importValue) {
            return;
        }
        try {
            const result = await applicantResumeImport(importValue);
            const resumeHash = result.data.resumeHash;
            onClose();
            push(`/resume/${resumeHash}`);
        } catch (_) {
            setImportError(true);
        }
    };

    const onSetImportValue = useCallback((value: string) => {
        setImportValue(value);
    }, []);

    const resetImportError = useCallback(() => {
        setImportError(false);
    }, []);

    const data = { importValue, importError };

    const handlers = {
        onImportSelect,
        onImportAgain,
        onSetImportValue,
        resetImportError,
    };

    return [data, handlers];
};
