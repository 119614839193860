import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';
import { AdditionalDefault, ModelData } from 'bloko/common/tree/types';

import { CurrencyType } from 'src/models/currencies.types';
import { PublicationProductType } from 'src/models/price/product.types';
import { ServiceItemCode } from 'src/models/price/productCodes';

export type PriceProfRolesPublicationsCodesType =
    | ServiceItemCode.Standart
    | ServiceItemCode.StandartPlus
    | ServiceItemCode.Premium
    | ServiceItemCode.Anonymous;

export interface PurchaseInfo {
    products: PublicationProductType[];
    price: number;
    currency: CurrencyType;
    discount: {
        fullPrice: number;
        profitPercent: number;
    };
}

export enum Variant {
    Single = 'single',
    Package = 'package',
}

// tempexp_40371_start
export enum ExperimentalVariant {
    Single = 'single',
    OneRoleAllRegions = 'oneRoleAllRegions',
    Package = 'package',
}
// tempexp_40371_end

export interface CountInfo {
    info?: string;
    value: number;
    period?: number;
}

export interface Scope {
    profRoleGroupId: string | null;
    priceRegionId: string;
    count: number;
}

interface CountThreshold {
    maxCount: number | null;
    minCount: number;
}

interface VariantInfo {
    defaultCounts: CountInfo[];
    defaultScope: Scope;
    scopes: Scope[];
    countThresholds: CountThreshold;
}

export interface ProfRoleGroupAdditional extends AdditionalDefault {
    includedProfRoleGroupsTrl: string;
    rolesQuantity: number;
}

export interface PriceProfRolesPublicationsType {
    amountNoticeCount: number;
    counts?: Record<string, CountInfo[]>;
    loading: boolean;
    profRoleGroupNotFoundSubstituteIds: string[];
    profRoleGroupTree: ModelData<ProfRoleGroupAdditional>[];
    profRoleGroupTrls: string[];
    purchasesByCode: Record<PriceProfRolesPublicationsCodesType, PurchaseInfo> | null;
    regionalThreshold: number;
    regions: ModelData[];
    regionPreTrls: string[];
    regionTrls: string[];
    variant: Variant;
    variants: Record<Variant, VariantInfo>;
    variantsOrder: Variant[];
    zpCrossPostAvailable: boolean;
    zpCrossPostChecked: boolean | null;
    taxonomyOnboardingShown: boolean;
    // tempexp_36140_next-line
    isNewEmployerWithoutServices?: boolean;
}

// tempexp_40371_start
export interface ExperimentalPriceProfRolesPublicationsType
    extends Omit<PriceProfRolesPublicationsType, 'variant' | 'variantsOrder' | 'variants' | 'regionalThreshold'> {
    tabName: string;
    variantsOrder: ExperimentalVariant[];
    variant: ExperimentalVariant;
    variants: Record<ExperimentalVariant, VariantInfo>;
    showNewPublicationVariantTip: boolean;
}
// tempexp_40371_end

const UPDATE_TAB_DATA = 'UPDATE_TAB_DATA';
const ADD_SCOPE = 'ADD_SCOPE';
const DELETE_SCOPE = 'DELETE_SCOPE';
const UPDATE_SCOPE = 'UPDATE_SCOPE';
const UPDATE_COUNTS = 'UPDATE_COUNTS';
const HIDE_TAXONOMY_ONBOARDING_TOOLTIP = 'HIDE_TAXONOMY_ONBOARDING_TOOLTIP';

interface PayloadPriceProfRolesPublicationsType {
    [UPDATE_TAB_DATA]: Partial<PriceProfRolesPublicationsType>;
    [ADD_SCOPE]: void;
    [DELETE_SCOPE]: number;
    [UPDATE_SCOPE]: {
        index: number;
        updatedScope: Scope;
        variant: Variant;
    };
    [UPDATE_COUNTS]: Record<string, CountInfo[]>;
    [HIDE_TAXONOMY_ONBOARDING_TOOLTIP]: void;
}

const actionCreator = ActionCreatorHelper<PayloadPriceProfRolesPublicationsType>();
export const updateTabData = actionCreator(UPDATE_TAB_DATA);
export const addScope = actionCreator(ADD_SCOPE);
export const deleteScope = actionCreator(DELETE_SCOPE);
export const updateScope = actionCreator(UPDATE_SCOPE);
export const updateCounts = actionCreator(UPDATE_COUNTS);
export const hideTaxonomyOnboardingTooltip = actionCreator(HIDE_TAXONOMY_ONBOARDING_TOOLTIP);

const priceProfRolesPublications = createReducer<PriceProfRolesPublicationsType, PayloadPriceProfRolesPublicationsType>(
    null,
    {
        [UPDATE_TAB_DATA]: (state, { payload }) => {
            return { ...state, ...payload };
        },
        [ADD_SCOPE]: (state) => {
            const variantInfo = state.variants[Variant.Single];
            const lastAddedScope = variantInfo.scopes[variantInfo.scopes.length - 1];
            const scopes = [
                ...variantInfo.scopes,
                { ...variantInfo.defaultScope, priceRegionId: lastAddedScope.priceRegionId },
            ];
            return { ...state, variants: { ...state.variants, [Variant.Single]: { ...variantInfo, scopes } } };
        },
        [DELETE_SCOPE]: (state, { payload: index }) => {
            const variantInfo = state.variants[Variant.Single];
            variantInfo.scopes.splice(index, 1);
            return {
                ...state,
                variants: { ...state.variants, [Variant.Single]: { ...variantInfo, scopes: [...variantInfo.scopes] } },
            };
        },
        [UPDATE_SCOPE]: (state, { payload: { index, updatedScope, variant } }) => {
            const variantInfo = state.variants[variant];
            variantInfo.scopes[index] = updatedScope;
            return {
                ...state,
                variants: { ...state.variants, [variant]: { ...variantInfo, scopes: [...variantInfo.scopes] } },
            };
        },
        [UPDATE_COUNTS]: (state, { payload }) => {
            return {
                ...state,
                counts: { ...state.counts, ...payload },
            };
        },
        [HIDE_TAXONOMY_ONBOARDING_TOOLTIP]: (state) => {
            return {
                ...state,
                taxonomyOnboardingShown: true,
            };
        },
    }
);

export { priceProfRolesPublications };
