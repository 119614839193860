import { useMemo } from 'react';

import { Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useEmployerBillingInfo } from 'src/components/SupernovaMainMenu/EmployerProfile/hooks/useEmployerBillingInfo';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { formatNumber } from 'src/utils/formatNumber';

import Informer from 'src/components/SupernovaMainMenu/EmployerProfile/Informers/Informer';

const TrlKeys = {
    vacancies: 'employer.employerProfile.popup.informers.vacancies',
    vacancyQuotas: 'employer.employerProfile.popup.informers.vacancyQuotas',
    of: 'employer.employerProfile.popup.informers.of',
};

const VacancyInformer: TranslatedComponent = ({ trls }) => {
    const { billingInfo } = useEmployerBillingInfo();
    const isZp = useIsZarplataPlatform();
    const serviceCategories = billingInfo?.billingSummary?.serviceCategories;

    const vacanciesCount = useMemo(() => {
        if (!serviceCategories) {
            return null;
        }
        const { nonFreeVacancyPublicationsCodes, freeVacancyPublicationsCodes } = serviceCategories;
        if (isZp) {
            return nonFreeVacancyPublicationsCodes?.count || 0;
        }
        return (freeVacancyPublicationsCodes?.count || 0) + (nonFreeVacancyPublicationsCodes?.count || 0);
    }, [isZp, serviceCategories]);

    if (billingInfo?.quotas?.vacanciesQuotas) {
        return (
            <Informer data-qa="vacancies-quotas" title={trls[TrlKeys.vacancyQuotas]}>
                {formatNumber(billingInfo.quotas.vacanciesQuotas)}{' '}
                <Text Element="span" style="secondary" typography="label-2-regular">
                    {trls[TrlKeys.of]} {formatNumber(vacanciesCount || 0)}
                </Text>
            </Informer>
        );
    }

    if (!!vacanciesCount || vacanciesCount === 0) {
        return (
            <Informer data-qa="vacancies_count" title={trls[TrlKeys.vacancies]}>
                {formatNumber(vacanciesCount)}
            </Informer>
        );
    }

    return null;
};

export default translation(VacancyInformer);
