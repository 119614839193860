import { FC, useRef, useEffect, useMemo } from 'react';

import footerAnalytics from 'src/components/Footer/analytics';
import { SupernovaLinkKind } from 'src/components/SupernovaMainMenu/Link';
import { useFeatureEnabled } from 'src/hooks/useFeatureEnabled';
import { NavItem } from 'src/models/supernovaNaviMenu';

import NavItemLink from 'src/components/Footer/NavItems/NavItemLink';

import styles from './docs-nav-items.less';

interface NaviItemsProps {
    nav?: NavItem;
    isMobileFriendly?: boolean;
    linkKind?: SupernovaLinkKind;
}

const Features = {
    employerMainPageAnalyticsEnabled: 'employer_main_page_analytics_enabled',
};

const NavItemsDocs: FC<NaviItemsProps> = ({
    nav,
    isMobileFriendly = false,
    linkKind = SupernovaLinkKind.MagritteNeutralSecondary,
}) => {
    const employerMainPageAnalyticsEnabled = useFeatureEnabled(Features.employerMainPageAnalyticsEnabled);
    const navLinksRef = useRef(null);
    const items = useMemo(() => nav?.subItems || [], [nav?.subItems]);
    const navName = nav?.name || '';

    useEffect(() => {
        if (employerMainPageAnalyticsEnabled && items?.length && navLinksRef.current) {
            footerAnalytics.navigationItemsShown(
                navLinksRef.current,
                navName,
                items.map((item) => item.name)
            );
        }
    }, [employerMainPageAnalyticsEnabled, items, navName]);

    return (
        <ul ref={navLinksRef} className={styles.navItemsWrapper}>
            {items?.map((menu) => (
                <li key={menu.name} className={styles.navItem}>
                    <NavItemLink
                        key={menu.url}
                        dataQa={`footer_${menu.name}${isMobileFriendly ? '-xs' : ''}`}
                        kind={linkKind}
                        onClick={
                            employerMainPageAnalyticsEnabled
                                ? () => footerAnalytics.navigationItemClick(menu.name)
                                : undefined
                        }
                        {...menu}
                    />
                </li>
            ))}
        </ul>
    );
};

export default NavItemsDocs;
