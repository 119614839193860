import { useSelector } from 'src/hooks/useSelector';
import { NavItem } from 'src/models/supernovaNaviMenu';
import { SupernovaSearchName } from 'src/models/supernovaSearchName';

export interface SearchNavItem extends NavItem {
    action: string;
}

const useSearchNavItemProps = (searchName: SupernovaSearchName): SearchNavItem | undefined =>
    useSelector(
        ({ headerMenu }) =>
            headerMenu
                ?.find((menuItem) => menuItem.name === 'searchForm')
                ?.subItems?.find((menuItem) => menuItem.name === searchName) as SearchNavItem
    );

export { useSearchNavItemProps };
