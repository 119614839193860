import { FC, useRef } from 'react';

import { Button } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import Logo from 'src/components/SupernovaMainMenu/Logo';
import SupernovaIcon, { SupernovaIconView } from 'src/components/SupernovaMainMenu/SupernovaIcon';
import { useTrackElementShown } from 'src/components/SupernovaMainMenu/useTrackElementShown';
import { useIsRedesignGeoExpActive } from 'src/hooks/useIsRedesignGeoExpActive';
import { NavItem } from 'src/models/supernovaNaviMenu';

import styles from './styles.less';

interface MobileMagritteHeaderProps {
    onLightBg: boolean;
    areaSwitcher?: NavItem;
    onAreaSwitcherClick?: () => void;
    onCloseClick: () => void;
}

const MobileMagritteHeader: FC<MobileMagritteHeaderProps> = ({
    onLightBg,
    areaSwitcher,
    onAreaSwitcherClick,
    onCloseClick,
}) => {
    const elementRef = useRef(null);
    const isRedesignGeoExpActive = useIsRedesignGeoExpActive();

    useTrackElementShown(elementRef, areaSwitcher?.name || '', areaSwitcher?.trackElementShown);

    return (
        <div className={styles.mobileScreenHeader}>
            <Logo isTinyOnXs isRedesign onLightBg={onLightBg} />
            {areaSwitcher && (
                <SupernovaIcon
                    magritteContrastStyle={!onLightBg}
                    isMagritte
                    ref={elementRef}
                    data-qa="mainmenu_areaSwitcher-xs"
                    name={areaSwitcher.name}
                    onClick={onAreaSwitcherClick}
                    trackClicks={areaSwitcher.trackClicks}
                    view={SupernovaIconView.GeoSwitcher}
                    analytics={areaSwitcher.analytics}
                >
                    {isRedesignGeoExpActive
                        ? areaSwitcher.translations.geoExpName || areaSwitcher.translations.name
                        : areaSwitcher.translations.name}
                </SupernovaIcon>
            )}
            <Button
                aria-label="close-button"
                hideLabel
                icon={<CrossOutlinedSize24 />}
                size="small"
                mode="tertiary"
                style={onLightBg ? 'neutral' : 'contrast'}
                onClick={onCloseClick}
            />
        </div>
    );
};

export default MobileMagritteHeader;
