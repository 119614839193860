import { FC, PropsWithChildren, useEffect, useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Button } from '@hh.ru/magritte-ui';
import {
    VkSolidSize24,
    FacebookSolidSize24,
    OkSolidSize24,
    TwitterSolidSize24,
    InstagramSolidSize24,
    TelegramSolidSize24,
    ViberSolidSize24,
    DzenSolidSize24,
} from '@hh.ru/magritte-ui/service';
import { SPALink } from '@hh.ru/redux-spa-middleware';

import footerAnalytics from 'src/components/Footer/analytics';
import { useFeatureEnabled } from 'src/hooks/useFeatureEnabled';
import { useSelector } from 'src/hooks/useSelector';

import SetkaSolidSize24 from 'src/components/Footer/SocialNetworks/Setka';

import styles from './social-networks.less';

const socialIconsMap = {
    vk: <VkSolidSize24 />,
    fb: <FacebookSolidSize24 />,
    ok: <OkSolidSize24 />,
    twitter: <TwitterSolidSize24 />,
    instagram: <InstagramSolidSize24 />,
    telegram: <TelegramSolidSize24 />,
    viber: <ViberSolidSize24 />,
    dzen: <DzenSolidSize24 />,
    setka: <SetkaSolidSize24 />,
};

const Features = {
    employerMainPageAnalyticsEnabled: 'employer_main_page_analytics_enabled',
};

const SocialNetworks: FC<PropsWithChildren> = () => {
    const employerMainPageAnalyticsEnabled = useFeatureEnabled(Features.employerMainPageAnalyticsEnabled);
    const links = useSelector(({ socialNetworksLinks }) => socialNetworksLinks);
    const socialNetworkItemsRef = useRef(null);

    useEffect(() => {
        if (employerMainPageAnalyticsEnabled && links?.length && socialNetworkItemsRef.current) {
            footerAnalytics.socialNetworkItemsShown(
                socialNetworkItemsRef.current,
                links.map((item) => item.name)
            );
        }
    }, [employerMainPageAnalyticsEnabled, links]);

    return (
        <div ref={socialNetworkItemsRef} className={styles.footerSocial}>
            {links?.map((link) => (
                <Button
                    mode="tertiary"
                    Element={SPALink}
                    to={link.url}
                    target="_blank"
                    key={link.name}
                    hideLabel
                    icon={socialIconsMap[link.name]}
                    aria-label={link.name}
                    rel="noopener noreferrer nofollow"
                    onClick={() => {
                        Analytics.sendHHEventButtonClick(`footer_${link.name}`, {
                            goal: `/virtual/click/social/${link.name}`,
                        });
                    }}
                >
                    {link.name}
                </Button>
            ))}
        </div>
    );
};

export default SocialNetworks;
