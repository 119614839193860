import React from 'react';

import { VendorAnalytics } from '@hh.ru/analytics-js';

import { useSelector } from 'src/hooks/useSelector';

const useGoogleAnalytics = (label: string, onClick?: () => void): (() => void) => {
    const userType = useSelector(({ userType }) => userType);

    return React.useCallback(() => {
        onClick && onClick();
        VendorAnalytics.eventToStorage(userType, 'header_new', label);
    }, [label, onClick, userType]);
};

export { useGoogleAnalytics };
