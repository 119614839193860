import { FormItem } from 'bloko/blocks/form';
import Radio from 'bloko/blocks/radio';
import Textarea from 'bloko/blocks/textarea';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { Answer, FormSteps, SKIP_ANSWER_VALUE, MultipleAnswer } from 'src/components/LoyaltySurveyModal/constants';
import translation from 'src/components/translation';

import CheckboxStep from 'src/components/LoyaltySurveyModal/Steps/CheckboxStep/CheckboxStep';

import styles from './steps.less';

interface StepProps {
    step: number;
    answers: (Answer | MultipleAnswer)[];
    positions: Record<FormSteps, number>;
    answersText?: Record<string, string>;
    formSteps: FormSteps[];
}

const MAX_LENGTH = 2000;

const VALUES_RADIO_WITH_NUMBERS_11 = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
const VALUES_RADIO_WITH_NUMBERS_5 = ['1', '2', '3', '4', '5'];

const TrlKeys = {
    steps: {
        [FormSteps.RecommendRadioWithNum]: {
            min: 'surveys.loyalty.popup.steps.3.min',
            max: 'surveys.loyalty.popup.steps.3.max',
        },
        [FormSteps.ProtectedPDRadioWithNum]: {
            min: 'surveys.loyalty.popup.steps.protectedPersonalData.min',
            max: 'surveys.loyalty.popup.steps.protectedPersonalData.max',
        },
        [FormSteps.NonTransferablePDRadioWithNum]: {
            min: 'surveys.loyalty.popup.steps.nonTransferablePersonalData.min',
            max: 'surveys.loyalty.popup.steps.nonTransferablePersonalData.max',
        },
    },
};

const Steps: TranslatedComponent<StepProps> = ({ trls, step, answers, positions, answersText, formSteps }) => {
    const renderRadioOptions = (values: string[]) => (
        <div className={styles.stepsSelector}>
            {values.map((index) => (
                <div className={styles.stepsSelectorRadio} key={`${step}-${index}`}>
                    <Radio
                        name={formSteps[step]}
                        value={index}
                        defaultChecked={'answerCode' in answers[step] && answers[step].answerCode === index}
                    >
                        <div className={styles.stepsSelectorLabel} data-qa="loyalty-survey-selector">
                            {index}
                        </div>
                    </Radio>
                </div>
            ))}
        </div>
    );

    const renderScaleDescription = () => {
        const stepConfig = TrlKeys.steps[formSteps[step] as keyof typeof TrlKeys.steps];
        if (!stepConfig) {
            return null;
        }
        return (
            <div className={styles.stepsSelectorDescription}>
                <div className={styles.stepsSelectorDescriptionMin}>{trls[stepConfig.min]}</div>
                <div className={styles.stepsSelectorDescriptionMax}>{trls[stepConfig.max]}</div>
            </div>
        );
    };

    switch (step) {
        case positions[FormSteps.PreferenceRadioWithText]:
        case positions[FormSteps.RatingRadioWithText]:
            return (
                <>
                    {Object.keys(answersText)
                        .reverse()
                        .map((value) => (
                            <FormItem key={`${step}-${value}`}>
                                <Radio
                                    name={formSteps[step]}
                                    value={value}
                                    defaultChecked={'answerCode' in answers[step] && answers[step].answerCode === value}
                                >
                                    {answersText?.[value]}
                                </Radio>
                            </FormItem>
                        ))}
                </>
            );
        case positions[FormSteps.ProtectedPDRadioWithNum]:
        case positions[FormSteps.NonTransferablePDRadioWithNum]:
            return (
                <>
                    {renderRadioOptions(VALUES_RADIO_WITH_NUMBERS_5)}
                    {renderScaleDescription()}
                </>
            );
        case positions[FormSteps.RecommendRadioWithNum]: {
            return (
                <>
                    {renderRadioOptions(VALUES_RADIO_WITH_NUMBERS_11)}
                    {renderScaleDescription()}
                </>
            );
        }
        case positions[FormSteps.ReasonsText]: {
            const defaultValue = ('answerText' in answers[step] && answers[step].answerText) || '';
            return (
                <Textarea
                    defaultValue={defaultValue === SKIP_ANSWER_VALUE ? '' : defaultValue}
                    autoFocus
                    name={formSteps[step]}
                    noresize
                    data-qa="loyalty-survey-textarea"
                    rows={6}
                    maxLength={MAX_LENGTH}
                />
            );
        }
        case positions[FormSteps.ImprovementsCheckboxes]: {
            return <CheckboxStep step={step} answers={answers} answersText={answersText} formSteps={formSteps} />;
        }
        default:
            return null;
    }
};
export default translation(Steps);
