import { ChangeEvent, FC } from 'react';

import switchRoleApplicantHeaderClick from '@hh.ru/analytics-js-events/build/xhh/common/header/switch_role_applicant_header_click';
import switchRoleEmployerHeaderClick from '@hh.ru/analytics-js-events/build/xhh/common/header/switch_role_employer_header_click';
import { Segment, Segmented, useBreakpoint } from '@hh.ru/magritte-ui';
import { usePush } from '@hh.ru/redux-spa-middleware';

import SupernovaNaviItem from 'src/components/SupernovaMainMenu/SupernovaNaviItem';
import { useTrackClicks } from 'src/components/SupernovaMainMenu/useTrackClicks';
import { NavItem } from 'src/models/supernovaNaviMenu';

import styles from './styles.less';

const UserTypeSegmented: FC<NavItem> = ({ subItems, name, trackClicks, analytics }) => {
    const { isS } = useBreakpoint();
    const push = usePush();
    const handleClick = useTrackClicks(name, trackClicks, analytics);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const clickedItem = subItems?.find((item) => item.name === event.target.value);
        if (clickedItem) {
            if (clickedItem?.name === 'userTypeSegmentedApplicant') {
                switchRoleApplicantHeaderClick();
            } else {
                switchRoleEmployerHeaderClick();
            }
        }
        handleClick();

        if (clickedItem?.url) {
            push(clickedItem.url);
        }
    };

    return (
        <SupernovaNaviItem userTypeSegmented>
            <div className={styles.segmentedContainer} style={{ background: '#f00' }}>
                <Segmented
                    stretched={!isS}
                    onChange={handleChange}
                    selectedValue={subItems?.find((item) => item.active)?.name || ''}
                    mode="label"
                    size="small"
                >
                    {subItems?.map((subItem) => {
                        return (
                            <Segment key={subItem.name} value={subItem.name}>
                                {subItem.translations.name}
                            </Segment>
                        );
                    })}
                </Segmented>
            </div>
        </SupernovaNaviItem>
    );
};

export default UserTypeSegmented;
