import { FC, useMemo } from 'react';

import Checkbox from 'bloko/blocks/checkbox';
import { FormItem } from 'bloko/blocks/form';
import Text from 'bloko/blocks/text';

import { Answer, FormSteps, MultipleAnswer } from 'src/components/LoyaltySurveyModal/constants';
import { getRandomStringArray } from 'src/components/LoyaltySurveyModal/utils';

interface CheckboxStepProps {
    step: number;
    answers: (Answer | MultipleAnswer)[];
    answersText?: Record<string, string>;
    formSteps: FormSteps[];
}

const CheckboxStep: FC<CheckboxStepProps> = ({ step, answersText, answers, formSteps }) => {
    const checkboxes = useMemo(() => {
        const lastKey = Number(Object.keys(answersText).at(-1));
        return getRandomStringArray(lastKey);
    }, [answersText]);

    const checkedCheckboxes =
        'answers' in answers[step] &&
        answers[step].answers.map((answer) => {
            return `${answer.answerCode}`;
        });

    return (
        <>
            {checkboxes.map((index) => (
                <FormItem key={`${step}-${index}`}>
                    <Checkbox
                        value={index}
                        name={formSteps[step]}
                        defaultChecked={checkedCheckboxes && checkedCheckboxes.includes(index)}
                    >
                        <Text>{answersText?.[index]}</Text>
                    </Checkbox>
                </FormItem>
            ))}
        </>
    );
};

export default CheckboxStep;
