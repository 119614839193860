import { useCallback } from 'react';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import pfpBalanceElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/pfp/pfp_balance_element_shown';
import pfpTopupButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/pfp/pfp_topup_button_click';
import { Button } from '@hh.ru/magritte-ui';
import { PlusOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { updateUrl } from 'Modules/url';
import paths from 'src/app/routePaths';
import FormatMoney from 'src/components/FormatMoney';
import { useEmployerBillingInfo } from 'src/components/SupernovaMainMenu/EmployerProfile/hooks/useEmployerBillingInfo';
import translation from 'src/components/translation';
import { CurrencyType } from 'src/models/currencies.types';

import Informer from 'src/components/SupernovaMainMenu/EmployerProfile/Informers/Informer';

const TrlKeys = {
    pfpBalance: 'employer.employerProfile.popup.informers.pfpBalance',
};

const HHTM_LABEL = 'employer_profile_menu';
const priceUrl = updateUrl(paths.pricePayForPerformance, { hhtmFromLabel: HHTM_LABEL });

// Баланс для оплаты откликов на вакансии PFP (Pay For Performance)
const PfpBalanceInformer: TranslatedComponent = ({ trls }) => {
    const { billingInfo } = useEmployerBillingInfo();
    const currency = billingInfo?.billingSummary?.currency || CurrencyType.RUR;
    const moneyCodes = billingInfo?.billingSummary?.serviceCategories?.moneyCodes;

    const onTopupButtonClick = useCallback(() => {
        pfpTopupButtonClick({ balance: moneyCodes?.count || 0, hhtmSourceLabel: HHTM_LABEL });
    }, [moneyCodes?.count]);

    if (!moneyCodes) {
        return null;
    }

    return (
        <Informer
            wide
            data-qa="pfp_money_count"
            title={trls[TrlKeys.pfpBalance]}
            action={
                <ElementShownAnchor fn={pfpBalanceElementShown} balance={moneyCodes.count} hhtmSourceLabel={HHTM_LABEL}>
                    <Button
                        Element={SPALink}
                        to={priceUrl}
                        mode="primary"
                        style="contrast"
                        icon={<PlusOutlinedSize24 />}
                        onClick={onTopupButtonClick}
                        data-qa="pfp_topup_button"
                    />
                </ElementShownAnchor>
            }
        >
            <FormatMoney currency={currency}>{moneyCodes.count}</FormatMoney>
        </Informer>
    );
};

export default translation(PfpBalanceInformer);
